import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './TeamCard.css';

export default class Card extends Component {
    render() {
        return (
            <div className="card small-card">
                <div className="image">
                    <img src={this.props.imgSrc} alt=""/>
                </div>
                <div class="content">
                    <a class="header">
                        {this.props.name}
                    </a>
                    <div class="header">
                        @{this.props.handle}
                    </div>
                    <div class="meta">
                        {this.props.title}
                    </div>
                    <div class="description"></div>
                </div>
                <div class="extra content">
                    <Link to={`/profile/${this.props.handle}`}>
                        <i class="add icon"></i>
                        Connect on laddr
                    </Link>
                </div>
            </div>
        ); 
    }
}
