import React, { Component } from 'react';
import { Menu, MenuItem } from 'semantic-ui-react';
import "./GoalMenu.css";
import firebase from 'firebase';

/* 
Props:
- goalList: Array of goal objects
- activeGoal: int
- currentUserId: String
*/

let MAX_NUMBER_OF_GOALS = 4;

class GoalMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeGoal: this.props.activeGoal || "",
            newGoal: "",
            displayAddGoalField: false,
            goalIds: Object.keys(this.props.goalList) || []
        }
        
        this.goalInputRef = React.createRef();
    }

    componentWillReceiveProps(props) {
        this.setState({
            goalIds: Object.keys(props.goalList),
            activeGoal: props.activeGoal || ""
        });
    }


    // Changes UI to show active goal and corresponding tasks
    handleMenuChange(index) {
        this.setState({
            activeGoal: index,
            displayAddGoalField: false
        });

        this.props.changeActiveGoal(index);
    }

    // Displays fields to add a goal when "Create goal" button is clicked
    toggleAddGoalField(addGoalFieldOn) {
        this.setState({
            displayAddGoalField: addGoalFieldOn
        }, () => {
            if (addGoalFieldOn && this.goalInputRef.current) {
                this.goalInputRef.current.focus();
            }
        });
    }

    // Adds new goal to the database
    addGoal() {
        if (this.state.newGoal != "") {
            var today = new Date();
            var day = today.getDate();
            var month = today.getMonth() + 1; //January is 0!
            var year = today.getFullYear();
            if (day < 10) {
                day = '0' + day;
            } 
            if (month < 10) {
                month = '0' + month;
            } 
            today = year + "-" + month + "-" + day;

            var goal = {
                DateCreated: today,
                Goal: this.state.newGoal,
                IsComplete: false,
                UserId: this.props.currentUserId
            }

            let goalDataRef = firebase.database().ref("Goals");
            let goalKey = goalDataRef.push(goal).key;

            let userGoalsRef = firebase.database().ref(`Users/${this.props.currentUserId}/Goals`);
            var componentRef = this;
            userGoalsRef.once("value", function(snapshot) {
                var goalIdList = snapshot ? (snapshot.val() || []) : [];
                goalIdList.push(goalKey);
                userGoalsRef.set(goalIdList, () => componentRef.handleMenuChange(goalKey));
                componentRef.setState({
                    newGoal: ""
                }, () => {
                    componentRef.toggleAddGoalField(false);
                });
            });
            /*var newGoalIds = this.state.goalIds;
            newGoalIds.push(goalKey);
            userGoalsRef.set(newGoalIds, () => this.handleMenuChange(goalKey));
            this.setState({
                newGoal: ""
            }, () => {
                this.toggleAddGoalField(false);
            });*/
        }
    }


    render() {
        var createGoalBtnClass = "create-goal-btn";
        if (Object.keys(this.props.goalList).length == 0) {
            createGoalBtnClass = createGoalBtnClass + " first-goal";
        }

        return (
            <Menu pointing secondary color={"blue"} className="goal-menu">
                {Object.keys(this.props.goalList).map((item, index) => {
                    var firstGoalClass = ""
                    if (index === 0) {
                        firstGoalClass = "first-goal"
                    }
                    return (
                        <Menu.Item 
                            active={this.state.activeGoal === item} 
                            key={index} 
                            className={firstGoalClass} 
                            onClick={() => this.handleMenuChange(item)}>
                            {this.props.goalList[item].Goal}
                        </Menu.Item>
                    )
                })}
                {(this.props.goalList && Object.keys(this.props.goalList).length < MAX_NUMBER_OF_GOALS && !this.props.isOtherUser) && 
                <MenuItem className={createGoalBtnClass} onClick={() => this.toggleAddGoalField(true)}>
                    {this.state.displayAddGoalField ? 
                        <div className="ui mini form">
                            <div className="fields new-goal-fields">
                                <div className="field">
                                    <input type="text" 
                                        placeholder="New Goal" 
                                        name="NewGoal" 
                                        maxLength="40"
                                        ref={this.goalInputRef} 
                                        value={this.state.newGoal}
                                        onBlur={(e) => {
                                            if (e.target.value == "") {
                                                this.setState({
                                                    displayAddGoalField: false
                                                });
                                            }
                                        }} 
                                        onChange={(e) => this.setState({newGoal: e.target.value})}/>
                                </div>
                                <div className="field">
                                    <button className="ui mini button primary" 
                                            onClick={() => this.addGoal()}>
                                            Add
                                    </button>
                                </div>
                            </div>
                        </div>
                    : "+ Create a goal"}
                </MenuItem>} 
            </Menu>
        );
    }
}

export default GoalMenu;