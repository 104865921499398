import React, { Component } from 'react';
import GoalCard from './GoalCard';
import './ProfileRight.css';
import { withRouter } from 'react-router-dom';
import firebase from "firebase";
import CompletedGoalModal from './CompletedGoalModal';

class ProfileRight extends Component {
    constructor(props) {
        super(props);
        this.state = {
            goalList: {},
            currentUser: null,
            pending: [],
            currentMembers: [],
            isGoalModalOpen: false,
            activeCompletedGoal: null,
            activeGoalID: null
        }
    }

    componentDidMount() {
        this.getGoals(this.props.urlUser.Goals);
    }

    componentWillReceiveProps(updatedProps) {
        this.getGoals(updatedProps.urlUser.Goals);
    }

    getGoals = (goalIDlist) => {
        let goalsRef = firebase.database().ref("Goals");
        let componentRef = this;
        goalsRef.on("value", function(snapshot) {
            if (snapshot && snapshot.val()) {
                let goalData = snapshot.val();
                var goals = {}
                var goalIds = goalIDlist || [];
                goalIds.forEach(function(goalId) {
                    goals[goalId] = goalData[goalId];
                });
                componentRef.setState({
                    goalList: goals
                });
            }
        });
    }

    render() {

        let communityUsersToShow = [];
        let pendingUsersToShow = [];
        if(this.props.urlUser.Community) {
            communityUsersToShow = this.props.urlUser.Community.filter(member => {
                return member.pending === false;
            });
            pendingUsersToShow = this.props.urlUser.Community.filter(member => {
                return member.pending === true;
            });
        }

        let completedGoals = [];
        let incompleteGoals = [];
        if(this.state.goalList) {
            Object.keys(this.state.goalList).map((goalID) => {
                if(this.state.goalList[goalID] && !this.state.goalList[goalID].IsComplete)
                    incompleteGoals.push({details: this.state.goalList[goalID], goalID: goalID});
                else if(this.state.goalList[goalID].IsComplete)
                    completedGoals.push({details: this.state.goalList[goalID], goalID: goalID});
            });
        }

        return (
            <div>
                <h2 className="header profile-header">
                    Goals
                    {incompleteGoals.length < 4 && this.props.onOwnProfile ?
                        <span className="profile-add-goal"><i className="plus circle icon" onClick={() => {this.props.history.push(`/plan/${this.props.currentUser.Username}`)}}/></span>
                        :
                        <span />
                    }
                </h2>
                <div className="ui cards profile-goal-cards">
                    {incompleteGoals.length > 0 ?
                        incompleteGoals.map((goal, index) => {
                            return <GoalCard details={goal.details} key={"cg" + index} goalID={goal.goalID} handleGoalClick={this.handleGoalClick}/>;
                        })
                        :
                        <p className="profile-header-margin profile-left-margin">No goals in progress</p>
                    }
                </div>
                <h2 className="header profile-header profile-header-margin">Completed Goals</h2>
                <div className="ui cards profile-goal-cards profile-completed-goals">
                    {completedGoals.length > 0 ?
                        completedGoals.map((goal, index) => {
                            return <GoalCard details={goal.details} key={"cg" + index} goalID={goal.goalID} handleGoalClick={this.handleCompletedGoalClick}/>;
                        })
                        :
                        <p className="profile-header-margin profile-left-margin">No goals completed yet</p>
                    }
                    {this.state.activeCompletedGoal ? 
                        <CompletedGoalModal 
                            isGoalModalOpen={this.state.activeCompletedGoal} 
                            activeCompletedGoal={this.state.activeCompletedGoal} 
                            activeGoalID={this.state.activeGoalID}
                            handleCloseGoalModal={() => this.setState({activeCompletedGoal: null})}
                            currentUser={this.props.currentUser}
                            currentUserID={this.props.currentUserID}
                            onOwnProfile={this.props.onOwnProfile}
                        /> 
                        : 
                        <div />
                    }
                </div>
                <h2 className="header profile-header profile-header-margin">Community</h2>
                <div className="profile-community-container">
                {this.props.urlUser.Community && communityUsersToShow.length > 0 ?
                    communityUsersToShow.map((user) => {
                        if(!user.pending) {
                            return (
                                <div className="profile-community-details">
                                    <img className="ui avatar image profile-community-img" src={user.profilePic} onClick={() => this.props.updateUrlUsername(user.username)}/>
                                    <div className="profile-community-username" onClick={() => this.props.updateUrlUsername(user.username)}>{user.username}</div>
                                </div>
                            );
                        }
                    })
                    :
                    <p className="profile-header-margin">No community to show</p>
                }
                </div>

                {this.props.onOwnProfile ? <h3 className="header profile-header profile-header-margin">Pending Requests</h3> : <div />}
                {this.props.onOwnProfile && pendingUsersToShow.length > 0 ?
                    pendingUsersToShow.map(user => {
                        if(user.pending) {
                            return (
                                <div className="profile-request-container">
                                    <div className="profile-request-detailContainer">
                                        <img className="ui avatar image profile-request-user" src={user.profilePic} onClick={() => window.location.href=`/profile/${user.username}`}/>
                                        <span className="profile-request-user profile-request-username" onClick={() => window.location.href=`/profile/${user.username}`}>{user.username}</span>
                                    </div>
                                    <div>
                                        <i className="icon check profile-request-accept" onClick={() => this.handleAcceptRequest(user.uid)}/>
                                        <i className="icon x icon profile-request-reject" onClick={() => this.handleRejectRequest(user.uid)}/>
                                    </div>
                                </div>
                            );
                        }
                    })
                    :
                    this.props.onOwnProfile ? <p className="profile-header-margin">No pending requests to show</p> : <div />
                }

            </div>
        )
    }

    handleCompletedGoalClick = (goalID) => {
        this.setState({
            activeCompletedGoal: this.state.goalList[goalID],
            activeGoalID: goalID
        });
    }

    handleGoalClick = (goalID) => {
        this.props.history.push(`/plan/${this.props.urlUser.Username}?q=${goalID}`);
    }

    handleAcceptRequest = (userID) => {
        this.props.addToSelf(userID);
        this.getCommunity(userID);
    }

    getCommunity = (userID) => {
        let userRef = firebase.database().ref(`Users/${userID}`);
        userRef.once("value", snapshot => {
            let user = snapshot.val();
            let community = user.Community || [];
            this.props.addToOtherMember(userID, community);
        });
    }

    handleRejectRequest = (userID) => {
        this.props.removeFromSelf(userID);
    }

}

export default withRouter(ProfileRight);