import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './semantic/dist/semantic.min.css'
import LandingPage from './pages/LandingPage/LandingPage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import PlanPage from './pages/PlanPage/PlanPage';
import * as serviceWorker from './serviceWorker';
import firebase from 'firebase';
import FeedPage from './pages/FeedPage/FeedPage';
import About from './pages/About/About';


var config = {
    apiKey: "AIzaSyCIu8OycIcWUB1EoQ1ZI6_htmjzLxc6704",
    authDomain: "info-capstone.firebaseapp.com",
    databaseURL: "https://info-capstone.firebaseio.com",
    projectId: "info-capstone",
    storageBucket: "info-capstone.appspot.com",
    messagingSenderId: "841307777595"
  };
firebase.initializeApp(config);

ReactDOM.render((
    <BrowserRouter>
        <Switch>
            <Route exact path='/' component={LandingPage} />
            <Route exact path='/sign-up' component={LandingPage} />
            <Route exact path='/log-in' component={LandingPage} />
            <Route exact path='/profile*' component={ProfilePage} />
            <Route exact path='/plan*' component={PlanPage} />
            <Route exact path='/community' component={FeedPage} />
            <Route exact path='/about' component={About} />
        </Switch>
    </BrowserRouter>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
