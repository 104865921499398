import React, { Component } from 'react';
import './LandingPage.css';
import Navbar from '../../common/navbar/Navbar';
import { Modal } from 'semantic-ui-react';
import SignUp from '../SignUp/SignUp';
import LogIn from '../LogIn/LogIn';
import firebase from "firebase";
import dashImg from "../../images/dash.svg";
import img1 from "../../images/graphic1.png";
import img2 from "../../images/graphic2.png";
import img3 from "../../images/graphic3.png";
import twitterlogo from "../../images/twitter-white.svg";
import instagramlogo from "../../images/instagram-white.svg";
import { Link } from 'react-router-dom';


class LandingPage extends Component {

    constructor(props) {
        super(props);

        let loginUrl = window.location.href.indexOf("log-in") !== -1;
        let signInUrl = window.location.href.indexOf("sign-up") !== -1;

        this.state = {
            signUpModalOpen: (loginUrl || signInUrl) ? true : false,
            modalState: loginUrl ? "log-in" : "sign-up",
            // numOfUsers: 0,
            isUserSignedIn: false,
            currentUser: null
        }
    }

    componentWillMount() {
        /*let usersRef = firebase.database().ref("Usernames");
        let componentRef = this;
        usersRef.on("value", function(snapshot) {
            var usernames = snapshot ? (snapshot.val() || {}) : {};
            componentRef.setState({
                numOfUsers: Object.keys(usernames).length
            });
        });*/
    }

    componentDidMount() {
        firebase.auth().onAuthStateChanged(currentUser => {
            if(currentUser) {
                let userRef = firebase.database().ref(`Users/${currentUser.uid}`);
                userRef.once("value", snapshot => {
                    let user = snapshot.val();
                    this.setState({
                        isUserSignedIn: true,
                        currentUser: user
                    });
                })
            } else {
                this.setState({isUserSignedIn: false});
            }
        });
    }

    showSignUpModal() {
        this.props.history.push("/sign-up");
        this.setState({
            signUpModalOpen: true
        });
    }

    hideModal() {
        this.setState({
            signUpModalOpen: false, 
            modalState: "sign-up"
        });
        this.props.history.push("/");
    }

    changeModalState(newState) {
        this.setState({
            modalState: newState,
            signUpModalOpen: true
        }, () => {
            if (newState == "log-in") {
                this.props.history.push("/log-in");
            }
        });
    }


    render() {

        return (
            <div>
                {/*<div className="banner">
                    <p>laddr is currently in beta.</p>
        </div>*/}
                <div className="navbar-container">
                    <Navbar />
                </div>
                <div className="container">
                    <div className="padded-content">
                        <div className="app-title">
                            <h1 className="app-title-h1">No experience?</h1>
                            <h1 className="app-title-h1">No problem.</h1>
                        </div>
                        <div className="app-subtitle">
                            <h3 className="app-subtitle-h3">Our mentors and peers are here to help.</h3>
                            <h3 className="app-subtitle-h3">Set goals, create a plan, and progress through your career.</h3>
                        </div>
                        {this.state.isUserSignedIn ?
                            <h4 className="app-title-name">Hi {this.state.currentUser.FirstName}!</h4>
                            :
                            <button className="ui primary button" onClick={() => this.showSignUpModal()}>Join us</button>
                        }
                    </div>
                </div>

                <Modal className="modal" open={this.state.signUpModalOpen} size="small">
                    <i className="times circle icon modal-close" onClick={() => this.hideModal()}></i>
                    {(this.state.modalState === "sign-up") ? 
                        <SignUp switchState={(newState) => this.changeModalState(newState)} /> : 
                        <LogIn />
                    }
                </Modal>

                <div className="landing-content">
                    <div className="landing-section">
                        <div className="landing-section-content">
                            <img src={dashImg} alt="dash" />
                            <h2 className="landing-section-title">Track your progress</h2>
                            <p className="landing-section-descr">One goal (or ten), one place. Personalize and manage your career plan by setting goals and assigned tasks.</p>
                        </div>
                        <img className="landing-section-img" src={img1} alt="plan page preview" />
                    </div>
                    <div className="landing-section">
                        <div className="landing-section-content">
                            <img src={dashImg} alt="dash" />
                            <h2 className="landing-section-title">Share your knowledge</h2>
                            <p className="landing-section-descr">Share and receive contextualized task suggestions, goal feedbacks, resume critiques, and career advice from peers, mentors, and industry leaders.</p>
                        </div>
                        <img className="landing-section-img" src={img2} alt="suggest task preview" />
                    </div>
                    <div className="landing-section">
                        <div className="landing-section-content">
                            <img src={dashImg} alt="dash" />
                            <h2 className="landing-section-title">Grow your career</h2>
                            <p className="landing-section-descr">From landing your first internship to moving into a full-time role, our laddr community is here to help!</p>
                            {!this.state.isUserSignedIn && <div>
                                <button className="ui button primary" onClick={() => this.showSignUpModal()}>Sign up</button> or <span className="sign-in-btn" onClick={() => this.changeModalState("log-in")}>Sign in</span>
                            </div>}
                        </div>
                        <img className="landing-section-img" src={img3} alt="collaboration" />
                    </div>
                </div>
                <div className="landing-footer">
                    <p className="laddr-label">laddr. @2019</p>
                    <Link to="/about"><p className="about-label">Our Team</p></Link>
                    <a href="https://twitter.com/laddrcommunity"><img className="socialmedia" src={twitterlogo}/></a>
                    <a href="https://instagram.com/laddrcommunity"><img className="socialmedia" src={instagramlogo}/></a>
                </div>
            </div>
        );
    }
}

export default LandingPage;
