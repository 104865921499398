import React, { Component } from 'react';
import firebase from 'firebase';

let DEFAULT_PROFILE_PIC = "http://clipart-library.com/images/ATbrxjpyc.jpg";

export default class ProfileCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            otherUserRequested: false
        }
    }

    componentDidMount() {
        // NOTE: Not a fan of this since it involves adding a listener, however, could not a find a neater way
        let currentUserRef = firebase.database().ref(`Users/${this.props.currentUserID}`);
        currentUserRef.on("value", snapshot => {
            let currentUser = snapshot.val();
            if(currentUser.Community) {
                let pendingRequests = currentUser.Community.filter(member => {
                    return member.uid === this.props.urlUserID;
                });
                if(pendingRequests.length > 0) {
                    this.setState({otherUserRequested: true});
                } else {
                    this.setState({otherUserRequested: false});
                }
            } else {
                this.setState({otherUserRequested: false});
            }
        })
    }

    handleUnfollowClick = () => {
        this.props.removeFromSelf(this.props.urlUserID);
        this.props.removeFromOtherMember();
    }

    handleAcceptRequest = () => {
        this.props.addToSelf(this.props.urlUserID);
        this.props.addToOtherMember(this.props.urlUserID, this.props.urlUser.Community);
    }

    render() {
        return(
            <div className="ui card">
                
                <div className="image">
                    <img src={this.props.profilePic || DEFAULT_PROFILE_PIC} alt="" />
                </div>

                <div className="content">

                    <h5 className="profile-username">@{this.props.username}</h5>

                    <div className="header profile-follow-container">
                        {this.props.firstName + " " + this.props.lastName}
                        {!this.props.onOwnProfile ?
                            (!this.props.alreadyRequestedUser ?
                                (!this.state.otherUserRequested ?
                                    <div className="ui label profile-follow-label" onClick={this.props.handleFollowClick}><i className="add icon" />Connect</div>
                                    :
                                    <div className="ui label profile-follow-label" onClick={this.handleAcceptRequest}>Accept</div>
                                )
                                :
                                (this.props.alreadyRequestedUser.pending ?
                                    <div className="ui label profile-requested-label">Requested</div>
                                    :
                                    <div className="ui label profile-unfollow-label" onClick={this.handleUnfollowClick}>Unfollow</div>
                                )
                            )
                            :
                            <div />
                        }
                    </div>

                    <div className="meta">
                        <span className="date">{this.props.careerOfInterest}</span>
                    </div>

                    <div className="description">
                        {this.props.description}
                    </div>

                    <h5>Skills</h5>
                    {this.props.tags && this.props.tags.split(",").map((tag, index) => {
                        return <div className="ui label" key={index}>{tag}</div>
                    })}

                    <h5>Find me elsewhere</h5>
                    <a href={this.props.github} target="_blank"><i className="github icon" /></a>
                    <a href={this.props.linkedin ? this.props.linkedin.linkedin : ""} target="_blank"><i className="linkedin icon" /></a>

                    {this.props.onOwnProfile ? <h5>Privacy</h5> : <div />}
                    {this.props.onOwnProfile ? <p>Your profile is {this.props.privacyStatus === "PUBLIC" ? "public" : "private"}</p> : <div />}

                </div>

                {!this.props.onOwnProfile && <div className="extra content">
                    <a href={"mailto:" + this.props.email}>
                        <i className="envelope icon" />
                        Contact {this.props.firstName}
                    </a>
                </div>}
                

                {!this.props.isEditing && this.props.onOwnProfile ?
                    <div className="extra content option-link" onClick={this.props.handleEditClick}>
                        <i className="pencil icon" />
                        Edit Profile
                    </div>
                    :
                    <div />
                }

            </div>
        );
    }
    
}