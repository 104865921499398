import React, { Component } from 'react';
import { Card } from 'semantic-ui-react';
import "./TaskCard.css";
import firebase from "firebase";
import { Link } from 'react-router-dom';

let DEFAULT_PROFILE_PIC = "http://clipart-library.com/images/ATbrxjpyc.jpg";

class TaskCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userInfo: {},
            creatorProfilePic: DEFAULT_PROFILE_PIC,
            type: this.props.type || ""
        }
    }

    componentWillMount() {
        this.getUserInfo(this.props.userId, this.props.creator)
    }

    componentWillReceiveProps(props) {
        this.getUserInfo(props.userId, props.creator)
        this.setState({
            type: props.type
        });
    }

    getUserInfo(userId, creator) {
        let userRef = firebase.database().ref(`Users/${userId}`);
        var componentRef = this;
        userRef.on("value", function(snapshot) {
            if (snapshot && snapshot.val()) {
                let data = snapshot.val();
                if (data["Username"] !== creator) {
                    componentRef.getCreatorProfilePic(creator);
                } else {
                    componentRef.setState({
                        creatorProfilePic: data["ProfilePic"]
                    })
                }
                componentRef.setState({
                    userInfo: data
                });
            }
        });
    }

    getCreatorProfilePic(creator) {
        let creatorRef = firebase.database().ref(`Usernames/${creator}`);
        var componentRef = this;
        creatorRef.on("value", function(snapshot) {
            if (snapshot && snapshot.val()) {
                let creatorId = snapshot.val();

                let creatorInfoRef = firebase.database().ref(`Users/${creatorId}/ProfilePic`);
                creatorInfoRef.on("value", function(snapshot) {
                    if (snapshot && snapshot.val()) {
                        componentRef.setState({
                            creatorProfilePic: snapshot.val()
                        })
                    }
                });
            }
        });
    }


    render() {
        var userInfo = this.state.userInfo;

        var profileLink = "/profile/" + this.props.creator;

        return (
            <Card fluid className="feed-task-card">
                {this.state.type === "COMPLETED" && <Card.Content>
                    Completed
                </Card.Content>}
                <Card.Content>
                    <img className="ui tiny top aligned circular image user-img" src={userInfo.ProfilePic || DEFAULT_PROFILE_PIC} alt="user" />
                    <div>
                        <Link to={`/profile/${userInfo.Username}`}>
                        <Card.Header>
                            <p className="task-user-name">
                            {userInfo.FirstName} {userInfo.LastName && userInfo.LastName.substring(0, 1)}{userInfo.LastName && "."}
                            </p>
                        </Card.Header>
                        <Card.Description className="handle" >@{userInfo.Username}</Card.Description>
                        </Link>
                    </div>
                    <h4 className="task-title">{this.props.task}</h4>
                    <p>{this.props.description}</p>
                </Card.Content>
                <Card.Content extra>
                    <img className="ui avatar image" src={this.state.creatorProfilePic || DEFAULT_PROFILE_PIC} />
                    <p className="task-extra-info">
                        {(this.props.creator === userInfo.Username) ? "Created by " : "Suggested by "}
                        <strong className="black-txt"><Link to={profileLink}>@{this.props.creator}</Link>
                        </strong> {this.props.dueDate && `Due on ${this.props.dueDate}`}
                    </p>
                </Card.Content>
            </Card>
        );
    }
}

export default TaskCard;


