import React, { Component } from 'react';
import Navbar from '../../common/navbar/Navbar';
import './About.css';
import Card from './TeamCard';
import jane from './profile/jane-laddr-square.jpg';
import zubin from './profile/zubin-laddr-square.jpg';
import andrea from './profile/andrea-laddr-square.jpg';
import ellani from './profile/ellani-laddr-square.jpg';

class About extends Component {
    render(){
        return (
            <div className="heading-container">  

                <Navbar />

                <div className="padded-content">
                    <div className="about-title">
                        <h1>About us</h1>
                    </div>
                </div>
                <hr className="section-line"/>
                <div className="padded-content">
                    <div className="ui cards flex-container">
                        <Card imgSrc={jane} name="Jane Q." handle="jane" title="Chaotic Software Wizard"/>
                        <Card imgSrc={zubin} name="Zubin C." handle="zubinchopra" title="Singing Code Dynamo"/>
                        <Card imgSrc={andrea} name="Andrea C." handle="abchen" title="GitHub Issue Guru"/>
                        <Card imgSrc={ellani} name="Ellani J." handle="ellani" title="Aesthetic Corn Nut Fanatic"/>
                        
                    </div>
                    <div class="grey-background">
                        <h2>We love hacks, music, notes, Trader Joe's, and building technology to create dreams into action.</h2>
                        <p>Hello! We're team JZAE (Jane, Zubin, Andrea, and Ellani) from the Information School at the University of Washington in Seattle. What you see as laddr community is the result of the culmination of our 6 month long Capstone project. Throughout the project we researched, conducted user studies, ideated, designed, developed, and built laddr community as a collaborative way to track and share your progress with your own community.</p>
                        <p>It wasn't easy building laddr community. We pivoted a few times, wrote messy code, and had many late nights, but we progressed forward towards our goal of building laddr community, and we're so excited to finally be able to share it with you. Building laddr community was our goal, and we can't wait to see you share your goals with us on laddr community!</p>
                        <h3>Keep on climbing! Best, Team JZAE</h3>
                        <p>
                        <button class="ui primary button spacing">
                            Contact us
                        </button>
                        </p>

                    </div>
                </div>

            </div>
        );
    }
}

export default About;
