import React, { Component } from 'react';
import { Card } from 'semantic-ui-react';
import "./UserCard.css";

let DEFAULT_PROFILE_PIC = "http://clipart-library.com/images/ATbrxjpyc.jpg";

class UserCard extends Component {

    render() {
        return (
            <Card fluid className="user-card">
                <Card.Content>
                <img className="ui tiny circular image user-card-img" src={this.props.profilePic} alt="user" />
                    <div>
                        <Card.Header>
                            <p className="current-user-name">
                                {this.props.firstName} {this.props.lastName.substring(0, 1)}{this.props.lastName && "."}
                            </p>
                        </Card.Header>
                        <Card.Description>@{this.props.username}</Card.Description>
                        <Card.Description className="supporter-descr"><strong>{this.props.communitySize}</strong> supporters with me</Card.Description>
                    </div>
                </Card.Content>
                <Card.Content extra>
                    <h5 className="black-txt">My current goals <span className="user-goal-count">({this.props.goalList ? this.props.goalList.length : 0})</span></h5>
                    <div className="ui list">
                        {this.props.goalList && this.props.goalList.map((item, index) => {
                            return (
                                <div key={index} className="item goal-list-item">
                                    <div className="goal-num-circle">
                                        <p className="goal-num">{index + 1}</p>
                                    </div> 
                                    <p>{item}</p>
                                </div>
                            );
                        })}
                    </div>
                </Card.Content>
            </Card>
        );
    }
}

export default UserCard;