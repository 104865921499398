import React, { Component } from 'react';
import { Card } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import "./GoalCard.css";

class GoalCard extends Component {

    render() {
        var tags = this.props.tags;
        if (typeof this.props.tags === 'string') {
            tags = tags.split(",");
        }
        
        return (
            <Card fluid className="goal-card">
                {this.props.isOtherUser && <Card.Content>
                    <img className="ui top aligned circular image user-img" src={this.props.profilePic} alt="user" />
                    <div>
                        <Link to={`/profile/${this.props.otherUsername}`}>
                        <Card.Header className="goal-card-header">
                            {this.props.firstName} {this.props.lastName.substring(0, 1)}{this.props.lastName && "."}
                        </Card.Header>
                        <Card.Description className="handle">@{this.props.otherUsername}</Card.Description>
                        </Link>
                    </div>
                </Card.Content>}
                <Card.Content>
                    {!this.props.isOtherUser && <i className="edit icon edit-goal-btn" onClick={() => this.props.editGoal()}></i>}
                    <Card.Header>Goal</Card.Header>
                    <Card.Description>{this.props.goal}</Card.Description>
                    <br />
                    {this.props.description && <Card.Header>Description</Card.Header>}
                    <Card.Description>{this.props.description}</Card.Description>
                    <br />
                    <div className="labels">
                        {tags && tags.map((item, index) => {
                            if (item !== "") {
                                return (
                                    <div className="ui grey small label" key={index}>
                                        <p>{item}</p>
                                    </div>
                                );
                            }
                        })}
                    </div>
                </Card.Content>
                <br />

                <Card.Content className="card-bottom" extra>
                    <p>Created {this.props.dateCreated}</p>
                </Card.Content>
            </Card>
        );
    }
}

export default GoalCard;