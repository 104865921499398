import React, { Component } from 'react';
import './LogIn.css';
import firebase from 'firebase';
import { withRouter } from 'react-router-dom';

class LogIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            message: "",
            currentUserId: ""
        }
    }

    componentDidMount() {
        firebase.auth().onAuthStateChanged(currentUser => {
            if(currentUser) {
                let currentUserRef = firebase.database().ref(`Users/${currentUser.uid}`);
                currentUserRef.once("value", snapshot => {
                    let user = snapshot.val();
                    this.props.history.push(`/profile/${user.Username}`);
                });
            }
        });
    }

    logIn(email, password) {
        firebase.auth().signInWithEmailAndPassword(email, password).catch((error) => {
            this.setState({message: "Error: " + error.message});
        })
    }

    render() {
        return(
            <div className="log-in">
                <h2>Log in</h2>
                <div className="ui form">
                    <div className="field">
                        <div className="ui icon input">
                            <input type="email" name="email" placeholder="Email" onChange={(e) => this.setState({email: e.target.value})} />
                            <i className="envelope icon"></i>
                        </div>
                    </div>
                    <div className="field">
                        <div className="ui icon input">
                            <input type="password" name="password" placeholder="Password" onChange={(e) => this.setState({password: e.target.value})} />
                            <i className="key icon"></i>
                        </div>
                    </div>
                    <button className="fluid primary ui button" type="submit" onClick={() => this.logIn(this.state.email, this.state.password)}>Log In</button> 
                    {/*<p className="forgot-password-link">Forgot your password?</p>*/}
                </div>

                {(this.state.message !== "") && <p className="ui negative message">{this.state.message}</p>}
            </div>
        );
    }
}


export default withRouter(LogIn);