import React, { Component } from 'react';
import Navbar from '../../common/navbar/Navbar';
import ProfileLeft from './ProfileLeft';
import ProfileRight from './ProfileRight';
import './ProfilePage.css';
import firebase from 'firebase';

let DEFAULT_PROFILE_PIC = "http://clipart-library.com/images/ATbrxjpyc.jpg";

export default class ProfilePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentUser: null,
            currentUserID: "",
            urlUser: null,
            urlUserID: "",
            notFound: false,
            onOwnProfile: false,
            alreadyRequestedUser: null,
            otherUserRequested: false,
            alreadyInCommunity: false,
            isEditing: false
        };
    }

    componentDidMount() {
        firebase.auth().onAuthStateChanged(currentUser => {
            if (currentUser) {
                
                this.setCurrentUser(currentUser);

                let urlUsername = window.location.pathname.split("/profile/")[1];

                if(urlUsername) {

                    let usernamesRef = firebase.database().ref(`Usernames`);
                    usernamesRef.once("value", snapshot => {
                        let usernames = Object.keys(snapshot.val());
                        usernames.forEach(username => {
                            if(username.toLowerCase() === urlUsername.toLowerCase()) {
                                let urlUsernameRef = firebase.database().ref(`Usernames/${username}`);
                                urlUsernameRef.once("value", snapshot => {
                                    let urlUserID = snapshot.val();
                                    this.setUrlUser(urlUserID)
                                });
                            }
                        });
                    });

                } else {
                    this.setUrlUser(currentUser.uid);
                }
            } else {
                this.setState({currentUser: ""})
            }
        });
    }

    componentWillReceiveProps(props) {
        let urlUsername = window.location.pathname.split("/profile/")[1];

        if(urlUsername) {

            let usernamesRef = firebase.database().ref(`Usernames`);
            usernamesRef.once("value", snapshot => {
                let usernames = Object.keys(snapshot.val());
                usernames.forEach(username => {
                    if(username.toLowerCase() === urlUsername.toLowerCase()) {
                        let urlUsernameRef = firebase.database().ref(`Usernames/${username}`);
                        urlUsernameRef.once("value", snapshot => {
                            let urlUserID = snapshot.val();
                            this.setUrlUser(urlUserID)
                        });
                    }
                });
            });

        }
    }

    setCurrentUser = (currentUser) => {
        let currentUserRef = firebase.database().ref(`Users/${currentUser.uid}`);
        currentUserRef.on("value", snapshot => {
            this.setState({
                currentUser: snapshot.val(),
                currentUserID: currentUser.uid
            });
        });
    }

    setUrlUser = (urlUserID) => {
        let urlUserRef = firebase.database().ref(`Users/${urlUserID}`);
        urlUserRef.on("value", snapshot => {
            let urlUser = snapshot.val();
            if(urlUser) {
                this.checkAlreadyRequested(urlUser);
                this.checkInCommunity(urlUser);
                this.setState({
                    urlUser: urlUser,
                    urlUserID: urlUserID,
                    onOwnProfile: this.state.currentUserID === urlUserID ? true : false,
                    notFound: false
                });
            } else {
                this.setState({
                    urlUser: null,
                    notFound: true
                });
            }
        });
    }

    checkAlreadyRequested = (urlUser) => {
        if(urlUser.Community) {
            let pendingRequests = urlUser.Community.filter(member => {
                return member.uid === this.state.currentUserID
            });
            if(pendingRequests.length > 0) {
                this.setState({alreadyRequestedUser: pendingRequests[0]});
            } else {
                this.setState({alreadyRequestedUser: null});
            }
        } else {
            this.setState({alreadyRequestedUser: null});
        }
    }

    checkInCommunity = (urlUser) => {
        if(urlUser.Community) {
            let communityMember = urlUser.Community.filter(member => {
                return member.uid === this.state.currentUserID && member.pending === false;
            });
            if(communityMember.length > 0) {
                this.setState({alreadyInCommunity: true});
            } else {
                this.setState({alreadyInCommunity: false});
            }
        } else {
            this.setState({alreadyInCommunity: false});
        }
    }

    handleFollowClick = () => {
        let userRef = firebase.database().ref(`Users/${this.state.urlUserID}`);
        let community = this.state.urlUser.Community || [];
        let member = {
            uid: this.state.currentUserID,
            pending: true,
            profilePic: this.state.currentUser.ProfilePic,
            username: this.state.currentUser.Username
        }
        community.push(member);
        userRef.update({
            Community: community
        }, err => {
            if(err) {
                console.log(err);
            }
        });
    }

    addToSelf = (userID) => {
        let updatedCommunity = this.state.currentUser.Community.map(user => {
            if(user.uid === userID) {
                user.pending = false;
            }
            return user
        });
        let currentUserRef = firebase.database().ref(`Users/${this.state.currentUserID}`);
        currentUserRef.update({
            Community: updatedCommunity
        }, err => {
            if(err) {
                console.log(err);
            }
        });
    }

    addToOtherMember = (userID, community) => {
        let member = {
            uid: this.state.currentUserID,
            pending: false,
            profilePic: this.state.currentUser.ProfilePic || DEFAULT_PROFILE_PIC,
            username: this.state.currentUser.Username
        }
        let memberCommunity = community || [];
        memberCommunity.push(member);
        let userRef = firebase.database().ref(`Users/${userID}`);
        userRef.update({
            Community: memberCommunity
        });
    }

    removeFromSelf = (userID) => {
        let currentUserCommunity = this.state.currentUser.Community;
        currentUserCommunity = currentUserCommunity.filter(member => {
            return member.uid !== userID
        });
        let currentUserRef = firebase.database().ref(`Users/${this.state.currentUserID}`);
        currentUserRef.update({
            Community: currentUserCommunity
        }, err => {
            if(err) {
                console.log(err);
            }
        });
    }

    removeFromOtherMember = () => {
        let urlUserCommunity = this.state.urlUser.Community;
        urlUserCommunity = urlUserCommunity.filter(member => {
            return member.uid !== this.state.currentUserID;
        });
        let urlUserRef = firebase.database().ref(`Users/${this.state.urlUserID}`);
        urlUserRef.update({
            Community: urlUserCommunity
        }, err => {
            if(err) {
                console.log(err);
            }
        });
    }

    updateUrlUsername = (username) => {
        let urlUsernameRef = firebase.database().ref(`Usernames/${username}`);
        urlUsernameRef.once("value", snapshot => {
            let urlUserID = snapshot.val();
            this.setUrlUser(urlUserID);
            this.props.history.push(`/profile/${username}`);
        });
    }

    handleEditClick = () => {
        this.setState({
            isEditing: true
        });
    }

    handleCancelClick = () => {
        this.setState({
            isEditing: false
        });
    }

    render() {
        return (
            <div>
                <Navbar updateUrlUsername={this.updateUrlUsername} handleCancelClick={this.handleCancelClick}/>
                {this.state.currentUser && this.state.urlUser ?
                <div className="profile-container">
                    <div className="profile-left-container">
                        <ProfileLeft 
                            isEditing={this.state.isEditing}
                            currentUser={this.state.currentUser}
                            currentUserID={this.state.currentUserID}
                            urlUser={this.state.urlUser}
                            urlUserID={this.state.urlUserID}
                            onOwnProfile={this.state.onOwnProfile}
                            alreadyRequestedUser={this.state.alreadyRequestedUser}
                            otherUserRequested={this.state.otherUserRequested}
                            handleFollowClick={this.handleFollowClick}
                            removeFromSelf={this.removeFromSelf}
                            removeFromOtherMember={this.removeFromOtherMember}
                            addToSelf={this.addToSelf}
                            addToOtherMember={this.addToOtherMember}
                            handleEditClick={this.handleEditClick}
                            handleCancelClick={this.handleCancelClick}
                        />
                    </div>
                    <div className="profile-right-container">
                        {this.state.onOwnProfile || (!this.state.onOwnProfile && this.state.urlUser.Privacy === "PUBLIC") || (!this.state.onOwnProfile && this.state.alreadyInCommunity) ?
                            <ProfileRight 
                                currentUser={this.state.currentUser}
                                currentUserID={this.state.currentUserID}
                                urlUser={this.state.urlUser}
                                urlUserID={this.state.urlUserID}
                                onOwnProfile={this.state.onOwnProfile}
                                addToSelf={this.addToSelf}
                                addToOtherMember={this.addToOtherMember}
                                removeFromSelf={this.removeFromSelf}
                                updateUrlUsername={this.updateUrlUsername}
                            />
                            :
                            <div>
                                <h1>Private Profile</h1>
                                <h3>Connect with {this.state.urlUser.FirstName} to know more about them!</h3>
                            </div>
                        }

                    </div>
                </div>
                :
                <div className="profile-error-container">
                    {!this.state.notFound ?
                    (this.state.currentUser !== "" ?
                        <div className="ui icon message">
                            <i className="notched circle loading icon"></i>
                            <div className="content">
                                <div className="header">Locating profile...</div>
                            </div>
                        </div>
                        :
                        this.props.history.push("/log-in")
                    )
                    :
                    <div />
                    }
                    {this.state.notFound ?
                    <div className="ui red message">
                        No user found!
                    </div>
                    :
                    <div />
                    }
                </div>
                }
            </div>
        );
    }

}