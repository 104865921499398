import React, { Component } from 'react';
import "./ModalForms.css";
import firebase from "firebase";


let MAX_NUM_OF_TAGS_PER_GOAL = 5;

class GoalEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            goal: this.props.goal || {
                Goal: "",
                Description: "",
                IsComplete: false,
                DateCreated: "",
                UserId: this.props.currentUserId,
            },
            initialTags: this.props.goal ? (this.props.goal.Tags || []) : [],
            emptyGoal: false,
            errorMessage: "",
            openDeleteConfirmation: false,
            tooManyTags: false
        }
    }

    handleEditGoalFieldChange(e) {
        var name = e.target.name;
        var value = e.target.value;
        var editedGoal = Object.assign({}, this.state.goal);
        editedGoal[name] = value;
        this.setState({
            goal: editedGoal
        });
    }

    formatArrayTagsAsString(tags) {
        if (Array.isArray(tags)) {
            if (tags.length == 0) {
                return "";
            }
            var result = tags[0];
            for (var i = 1; i < tags.length; i++) {
                result = result +"," + tags[i];
            }
            return result;
        }
        return tags;
    }

    validateGoal(goal) {
        if (!goal.Goal) {
            this.setState({
                emptyGoal: true,
                errorMessage: "Don't forget to add a goal title!"
            });
            return false;
        } else {
            this.setState({
                emptyGoal: false,
                errorMessage: ""
            });
        }
        if (goal.Tags && goal.Tags.split(",").length > MAX_NUM_OF_TAGS_PER_GOAL) {
            this.setState({
                tooManyTags: true,
                errorMessage: `You can only add ${MAX_NUM_OF_TAGS_PER_GOAL} tags to a goal!`
            });
            return false;
        } else {
            this.setState({
                tooManyTags: false,
                errorMessage: ""
            })
        }
        return true;
    }

    saveGoalEdit(goal) {
        if (this.validateGoal(goal)) {
            if (this.props.activeGoal) {
                let databaseRef = firebase.database().ref(`Goals/${this.props.activeGoal}`);
                var tags = goal.Tags;
                if (tags === "") {
                    tags = null;
                }
        
                if (typeof tags === "string") {
                    goal.Tags = tags.split(",");
                }
                this.deleteRemovedTags(goal.Tags);
                this.addGoalIdsToTagData(goal.Tags);
                databaseRef.set(goal);
            } else {
                var today = new Date();
                var day = today.getDate();
                var month = today.getMonth() + 1; //January is 0!
                var year = today.getFullYear();
                if (day < 10) {
                    day = '0' + day;
                } 
                if (month < 10) {
                    month = '0' + month;
                } 
                today = year + "-" + month + "-" + day;
                goal["DateCreated"] = today;
                let goalDataRef = firebase.database().ref("Goals");
                let goalKey = goalDataRef.push(goal).key;
    
                let userGoalsRef = firebase.database().ref(`Users/${this.props.currentUserId}/Goals`);
    
                userGoalsRef.once("value", function(snapshot) {
                    var goalIds = (snapshot && snapshot.val()) ? snapshot.val() : [];
                    goalIds.push(goalKey);
                    userGoalsRef.set(goalIds);
                });
            }
            this.props.closeModal();
        }
    }

    deleteRemovedTags(tags) {
        let componentRef = this;
        this.state.initialTags.forEach(function(oldTag) {
            var prohibitedChars = /[\.\$\[\]\#\/]/;
            if (!tags.includes(oldTag) && !prohibitedChars.test(oldTag)) {
                componentRef.deleteGoalReferenceFromTagData(oldTag);
            }
        });
    }

    addGoalIdsToTagData(tags) {
        if (tags) {
            let tagDataRef = firebase.database().ref(`Tags`);
            let componentRef = this;
            tagDataRef.once("value", function(snapshot) {
                let tagData = snapshot.val() || {};
                tags.forEach(function(item) {
                var prohibitedChars = /[\.\$\[\]\#\/]/;
                    var goalIds = tagData[item.toLowerCase()] || [];
                    if (!goalIds.includes(componentRef.props.activeGoal) && !prohibitedChars.test(item)) {
                        goalIds.push(componentRef.props.activeGoal);
                        let tagsRef = firebase.database().ref(`Tags/${item.toLowerCase()}`);
                        tagsRef.set(goalIds);
                    }
                });
            });
        }
    }

    deleteGoalReferenceFromTagData(tag) {
        let componentRef = this;
        let tagDataRef = firebase.database().ref(`Tags/${tag.toLowerCase().trim()}`);
        tagDataRef.once("value", function(snapshot) {
            var tagData = snapshot.val() || [];
            var index = tagData.indexOf(componentRef.props.activeGoal);
            if (index > -1) {
                tagData.splice(index, 1);
            }
            tagDataRef.set(tagData);
        });
    }


    deleteGoal() {
        // Delete goal reference from all of it's tags
        var tags = this.state.goal.Tags || [];
        let componentRef = this;
        tags.forEach(function(tag) {
            componentRef.deleteGoalReferenceFromTagData(tag);
        });

        // Delete goal reference from the user schema
        let userGoalRef = firebase.database().ref(`Users/${this.props.currentUserId}/Goals`);
        userGoalRef.once("value", function(snapshot) {
            if (snapshot && snapshot.val()) {
                var goalIds = snapshot.val();
                var index = goalIds.indexOf(componentRef.props.activeGoal);
                if (index > -1) {
                    goalIds.splice(index, 1);
                }
                userGoalRef.set(goalIds);
            }
        });

        // Delete all tasks associated with goal
        let taskIdsRef = firebase.database().ref(`Goals/${this.props.activeGoal}/TaskList`);
        taskIdsRef.once("value", function(snapshot) {
            var taskRefList = snapshot ? (snapshot.val() || []) : [];
            taskRefList.forEach(function(taskRefObj) {
                var taskId = taskRefObj.TaskID;
                let taskRef = firebase.database().ref(`Tasks/${taskId}`);
                taskRef.set(null);
            });
        });

        // Delete goal object from database
        let goalRef = firebase.database().ref(`Goals/${this.props.activeGoal}`);
        goalRef.set(null);
        this.props.closeModal();
    }


    render() {
        var goalClass = "field";
        if (this.state.emptyGoal) {
            goalClass = goalClass + " error";
        }

        var tagClass = "field";
        if (this.state.tooManyTags) {
            tagClass = tagClass + " error";
        }

        var saveButtonClass = "eight wide field";
        if (!this.props.activeGoal) {
            saveButtonClass = "sixteen wide field";
        }

        return (
            <div>
                {!this.state.openDeleteConfirmation ? <div>
                    <h2>{this.props.activeGoal ? "Edit " : "Create a "} Goal</h2>
                    <div className="ui form modal-form">
                        <div className={goalClass}>
                            <input type="text" 
                                name="Goal" 
                                placeholder="Goal" 
                                maxLength="40"
                                onChange={(e) => this.handleEditGoalFieldChange(e)} 
                                value={this.state.goal ? this.state.goal.Goal : ""} />        
                        </div>
                        <div className="field">
                            <textarea placeholder="Add a description about your goal. Briefly share what your goal is with your community and supporters." name="Description" onChange={(e) => this.handleEditGoalFieldChange(e)} value={this.state.goal ? this.state.goal.Description : ""}></textarea>
                        </div>
                        <div className={tagClass}>
                            <textarea placeholder="Add tags as a comma separated list so others can find your goal." name="Tags" onChange={(e) => this.handleEditGoalFieldChange(e)} value={this.state.goal ? this.formatArrayTagsAsString(this.state.goal.Tags) : ""}></textarea>
                        </div>
                        <div className="fields">
                            {this.props.activeGoal && <div className="eight wide field">
                                <button className="ui fluid negative button" onClick={() => this.setState({
                                    openDeleteConfirmation: true
                                })}>Delete goal</button>
                            </div>}
                            <div className={saveButtonClass}>
                                <button className="ui fluid button primary" onClick={() => this.saveGoalEdit(this.state.goal)}>{this.props.activeGoal ? "Save changes" : "Create a goal"}</button>               
                            </div>
                        </div>
                        {this.state.errorMessage &&
                            <div className="ui negative message error-message">
                                <p>{this.state.errorMessage}</p>
                            </div>
                        }
                    </div>
                </div> : 
                <div className="modal-form">
                    <h2 className="modal-title">Delete Goal?</h2>
                    <p className="modal-content">Deleting is permanent and cannot be undone. This will delete all tasks, comments, data, and access to this goal.</p>
                    <button className="ui fluid button negative" onClick={() => this.deleteGoal()}>Yes, I understand. Delete this goal.</button>
                </div>}
            </div>
        );
    }
}

export default GoalEditor;