import React, { Component } from 'react';
import Navbar from '../../common/navbar/Navbar';
import { withRouter } from 'react-router-dom';
import { Menu, FeedContent } from 'semantic-ui-react';
import "./FeedPage.css";
import firebase from "firebase";
import UserCard from "./UserCard";
import Feed from "./Feed";

let DEFAULT_PROFILE_PIC = "http://clipart-library.com/images/ATbrxjpyc.jpg";

class FeedPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeItem: "following",
            isLoading: true,
            currentUserId: "",
            firstName: "",
            lastName: "",
            username: "",
            profilePic: DEFAULT_PROFILE_PIC,
            goalList: [],
            communitySize: 0,
            publicFeed: {},
            communityFeed: {}
        }
    }

    componentWillMount() {
        let componentRef = this;
        firebase.auth().onAuthStateChanged(function(currentUser) {
            if (currentUser) {
                componentRef.setState({
                    currentUserId: currentUser.uid
                });
                componentRef.getUserInfo(currentUser.uid);
                componentRef.getCommunityFeed(currentUser.uid);
                componentRef.getPublicFeed();
            } else {
              componentRef.props.history.push("/log-in")
            }
        });
    }

    getCommunityFeed(userId) {
        let communityFeedRef = firebase.database().ref(`Users/${userId}/CommunityFeed`);
        var componentRef = this;
        communityFeedRef.on("value", function(snapshot) {
            let communityFeed = {}
            if (snapshot && snapshot.val()) {
                communityFeed = snapshot.val();
            }
            var feed = {};
            let taskRef = firebase.database().ref("Tasks");
            taskRef.on("value", function(snapshot) {
                var taskData = snapshot ? (snapshot.val() || {}) : {};
                Object.keys(communityFeed).forEach(function(feedId) {
                    var feedData = communityFeed[feedId];
                    if (feedData && feedData.TaskID && taskData[feedData.TaskID]) {
                        var task = Object.assign({}, taskData[feedData.TaskID]);
                        task["Type"] = feedData.Type;
                        feed[feedId] = task;
                    } else {
                        communityFeed[feedId] = null;
                        communityFeedRef.set(communityFeed);
                    }
                });
                componentRef.setState({
                    communityFeed: feed
                });
            });
        });
    }

    getPublicFeed() {
        let publicFeedRef = firebase.database().ref("Public Feed");
        var componentRef = this;
        publicFeedRef.on("value", function(snapshot) {
            let publicFeed = {}
            if (snapshot && snapshot.val()) {
                publicFeed = snapshot.val();
            }

            var feed = {};

            let taskRef = firebase.database().ref("Tasks");
            taskRef.on("value", function(snapshot) {
                var taskData = snapshot ? (snapshot.val() || {}) : {};
                Object.keys(publicFeed).forEach(function(feedId) {
                    var feedData = publicFeed[feedId];
                    if (feedData && feedData.TaskID && taskData[feedData.TaskID]) {
                        var task = Object.assign({}, taskData[feedData.TaskID]);
                        task["Type"] = feedData.Type;
                        feed[feedId] = task;
                    } else {
                        publicFeed[feedId] = null;
                        publicFeedRef.set(publicFeed);
                    }
                });
                componentRef.setState({
                    publicFeed: feed,
                    isLoading: false
                });
            });
        });
    }

    getUserInfo(currentUserId) {
        let userRef = firebase.database().ref(`Users/${currentUserId}`);
        var componentRef = this;
        userRef.on("value", function(snapshot) {
            if (snapshot && snapshot.val()) {
                var data = snapshot.val();
                componentRef.getUserGoals(data["Goals"] || []);
                componentRef.setState({
                    firstName: data["FirstName"],
                    lastName: data["LastName"],
                    profilePic: data["ProfilePic"] || DEFAULT_PROFILE_PIC,
                    username: data["Username"],
                    communitySize: data["Community"] ? data["Community"].length : 0
                });
            }
        });
    }

    getUserGoals(goalIds) {
        let goalsRef = firebase.database().ref("Goals");
        var componentRef = this;
        goalsRef.on("value", function(snapshot) {
            if (snapshot && snapshot.val()) {
                let goalsData = snapshot.val();
                var goalList = [];
                goalIds.forEach(function(id) {
                    if (goalsData[id] && !goalsData[id].IsComplete) {
                        goalList.push(goalsData[id].Goal);
                    }
                });
                componentRef.setState({
                    goalList: goalList,
                    isLoading: false
                })
            }
        });
    }

    handleItemClick(name) {
        this.setState({
            activeItem: name
        });
    }


    render() {

        return (
            <div className="feed-page">
                <Navbar />
                <h1 className="feed-content">Community</h1>
                <Menu pointing secondary color={"blue"} className="feed-menu">
                    <Menu.Item className="feed-first-menu-item" active={this.state.activeItem === "following"} onClick={() => this.handleItemClick("following")}>Following</Menu.Item>
                    <Menu.Item active={this.state.activeItem === "public"} onClick={() => this.handleItemClick("public")}>Public</Menu.Item>
                </Menu>
                {this.state.isLoading ? <div className="ui active loader"></div> :
                <div className="feed-content">
                    <div className="feed-right">
                        <UserCard 
                            firstName={this.state.firstName}
                            lastName={this.state.lastName}
                            profilePic={this.state.profilePic}
                            username={this.state.username}
                            goalList={this.state.goalList}
                            communitySize={this.state.communitySize}
                        />
                    </div>
                    <div className="feed-left">
                        {this.state.activeItem === "public" ? <Feed feed={this.state.publicFeed} /> : <Feed feed={this.state.communityFeed} />}
                    </div>
                </div>}
            </div>
        );
    }
}

export default withRouter(FeedPage);