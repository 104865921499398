import React, { Component } from 'react';
import "./TaskList.css";
import TaskCard from "./TaskCard";
import firebase from "firebase";

class TaskList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            taskData: []
        }
    }

    componentWillMount() {
        this.updateTasks(this.props.currTaskList);
    }

    componentWillReceiveProps(props) {
        this.updateTasks(props.currTaskList);
    }

    updateTasks(taskIdList) {
        let taskDataRef = firebase.database().ref("Tasks");
        var componentRef = this;
        taskDataRef.on("value", function(snapshot) {
            if (snapshot && snapshot.val()) {
                var data = snapshot.val();
                var taskList = [];
                taskIdList.forEach(function(taskInfo) {
                    taskList.push(data[taskInfo["TaskID"]]);
                });
                componentRef.setState({
                    taskData: taskList
                });
            }
        });
    }



    render() {
        
        return(
            <div className="task-list">
                <h3 className="task-list-title">{this.props.title} 
                    <span className="task-count"> ({this.props.taskCount}) </span>
                    {(this.props.taskStatus === "suggested" && !this.props.isOtherUser) && <i className="circle icon new-task-indicator"></i>}
                </h3>
                {this.state.taskData && this.state.taskData.map((item, index) => {
                    if (item && item.Status && item.Status === this.props.taskStatus) {
                        return(
                            <TaskCard 
                                complete={item.Status === "complete"} 
                                isOtherUser={this.props.isOtherUser}
                                creator={item.Creator}
                                description={item.Description} 
                                task={item.Task} 
                                taskId={this.props.currTaskList[index].TaskID}
                                taskStatus={this.props.taskStatus}
                                taskNumber={index}
                                privacyStatus={this.props.privacyStatus}
                                community={this.props.community}
                                currentUserId={this.props.currentUserId}
                                currentUsername={this.props.currentUsername}
                                activeGoal={this.props.activeGoal}
                                editTask={(taskNum, taskId) => this.props.editTask(taskNum, taskId)}
                                dueDate={item.DueDate} 
                                key={index} />
                        );
                    }
                })}
            </div>
        );
    }
}

export default TaskList;