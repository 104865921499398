import React, { Component } from 'react';
import "./ModalForms.css";
import firebase from "firebase";

class TaskEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            task: {},
            emptyTask: false,
            errorMessage: ""
        }
    }

    componentWillMount() {
        var taskId = this.props.task.TaskID;
        let taskRef = firebase.database().ref(`Tasks/${taskId}`);
        var componentRef = this;
        taskRef.on("value", function(snapshot) {
            if (snapshot && snapshot.val()) {
                componentRef.setState({
                    task: snapshot.val()
                });
            }
        });

    }

    // Updates edited task in database
    saveTaskEdit(task) {
        if (task.Task) {
            // Update task on task branch
            let taskRef = firebase.database().ref(`Tasks/${this.props.taskId}`);
            taskRef.set(task);

            // Update task on goal branch
            let goalTaskRef = firebase.database().ref(`Goals/${this.props.activeGoal}/TaskList`);
            var taskList = this.props.currTaskList || [];
            var updatedTask = {
                TaskID: this.props.taskId,
                DueDate: task.DueDate,
                Status: task.Status
            }
            var componentRef = this;
            if (updatedTask.DueDate) {
                goalTaskRef.once("value", function(snapshot) {
                    var oldTaskList = snapshot ? (snapshot.val() || []) : [];
                    oldTaskList.splice(componentRef.props.taskNum, 1);
                    var taskDate = new Date(updatedTask.DueDate);
                    var numberOfTasks = oldTaskList.length;
                    var itemAdded = false;
                    
                    for (var i = 0; i < numberOfTasks; i++) {
                        var taskItemDate = new Date(oldTaskList[i].DueDate);
                        if ((taskDate <= taskItemDate || !oldTaskList[i].DueDate) && !itemAdded) {
                            oldTaskList.splice(i, 0, updatedTask);
                            itemAdded = true
                        }
                    }
                    if (oldTaskList.length === 0 || !itemAdded) {
                        oldTaskList.push(updatedTask);
                    }
                    goalTaskRef.set(oldTaskList);
                });
            } else {
                goalTaskRef.child(componentRef.props.taskNum).set(updatedTask);
            }
            this.props.closeModal();
        } else {
            this.setState({
                emptyTask: true,
                errorMessage: "Be sure to add a title before adding tasks!"
            });
        }
    }


    // Updates task as user makes edits
    handleEditTaskFieldChange(e) {
        var field = e.target.name;
        var value = e.target.value;
        var editedTask = Object.assign({}, this.state.task);

        editedTask[field] = value;
        this.setState({
            task: editedTask
        });
    }

    handleDeleteTask() {
        // Delete task reference from goal branch
        let goalTaskRef = firebase.database().ref(`Goals/${this.props.activeGoal}/TaskList`);
        var componentRef = this;
        goalTaskRef.once("value", function(snapshot) {
            if (snapshot && snapshot.val()) {
                var tasks = snapshot.val();
                tasks.splice(componentRef.props.taskNum, 1);
                goalTaskRef.set(tasks);
            }
        });

        // Delete task reference from public feed
        let taskRef = firebase.database().ref(`Tasks/${this.props.taskId}`);
        taskRef.once("value", function(snapshot) {
            if (snapshot && snapshot.val()) {
                var data = snapshot.val();
                var feedIDs = data["PublicFeedIDs"] || [];
                feedIDs.forEach(function(id) {
                    let feedRef = firebase.database().ref(`Public Feed/${id}`);
                    feedRef.set(null);
                });
            }
            // Delete task reference from task branch
            taskRef.set(null);
        });

        this.props.closeModal();
    }


    render() {
        var taskClass = "field";
        if (this.state.emptyTask) {
            taskClass = taskClass + " error";
        }

        return (
            <div>
                <h2>Edit Task</h2>
                <div className="ui form modal-form">
                    <div className={taskClass}>
                        <input type="text" 
                                name="Task" 
                                maxLength="40"
                                placeholder="Task" 
                                value={this.state.task ? this.state.task.Task : ""} 
                                onChange={(e) => this.handleEditTaskFieldChange(e)} />
                    </div>
                    <div className="field">
                        <textarea placeholder="Description" 
                                name="Description" 
                                value={this.state.task ? this.state.task.Description : ""} 
                                onChange={(e) => this.handleEditTaskFieldChange(e)}>
                        </textarea>
                    </div>
                    <div className="eight wide field">
                        <div className="ui calendar" id="example1">
                            <div className="ui input left icon">
                                <i className="calendar icon"></i>
                                <input 
                                    type="text"  
                                    value={this.state.task ? this.state.task.DueDate : ""}
                                    onChange={(e) => this.handleEditTaskFieldChange(e)}
                                    required="required"
                                    name="DueDate"
                                    placeholder="Due date" 
                                    onFocus={(e) => {
                                        e.target.type = "date"
                                    }} 
                                    onBlur={(e) => {
                                        e.target.type = "text"
                                    }}/>
                            </div>
                        </div>
                    </div>
                    <div className="fields">
                        <div className="eight wide field">
                            <button className="ui fluid button negative"
                                    onClick={() => this.handleDeleteTask()}>
                                    Delete Task
                            </button>
                        </div>
                        <div className="eight wide field">
                            <button className="ui fluid button primary" 
                                    onClick={() => this.saveTaskEdit(this.state.task)}>
                                    Save Changes
                            </button>
                        </div>
                    </div>
                    {this.state.errorMessage &&
                        <div className="ui negative message error-message">
                            <p>{this.state.errorMessage}</p>
                        </div>
                    }
                </div>
            </div>

        );
    }
}

export default TaskEditor;