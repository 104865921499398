import React, { Component } from 'react';
import TaskCard from "./TaskCard";
import firebase from "firebase";
import "./Feed.css";


class Feed extends Component {
    constructor(props) {
        super(props);

        this.state = {
            feed: this.props.feed || {},
            isLoading: true
        }
    }

    componentDidMount() {
        this.setState({
            isLoading: false
        });
    }

    componentWillReceiveProps(props) {
        this.setState({
            feed: props.feed || {}
        });
    }


    render() {
        console.log("test", Object.keys(this.state.feed).length != 0 )
        return(
            <div className="ui segment feed">
                {Object.keys(this.state.feed).length === 0 && 
                <p className="no-activity-msg">No recent activity.</p>}
                {this.state.isLoading ? <div className="ui active loader feed-loader"></div> :
                <div>
                    {Object.keys(this.state.feed).slice(0).reverse().map((item, index) => {
                        return (
                            <TaskCard 
                                key={index}
                                userId={this.state.feed[item].UserID}
                                task={this.state.feed[item].Task}
                                dueDate={this.state.feed[item].DueDate}
                                description={this.state.feed[item].Description}
                                creator={this.state.feed[item].Creator}
                                type={this.state.feed[item].Type}
                            />
                        );
                    })}
                </div>}
            </div>
        );
    }
}

export default Feed;