import React, { Component } from 'react';
import { Modal, Menu, Card, Button } from 'semantic-ui-react';
import './CompletedGoalModal.css';
import firebase from 'firebase';

export default class CompletedGoalModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            completedTasks: [],
            incompleteTasks: [],
            onMenu: "completed"
        }
    }

    componentDidMount() {
        if(this.props.activeCompletedGoal.TaskList) {
            this.props.activeCompletedGoal.TaskList.map(taskID => {
                let taskRef = firebase.database().ref(`Tasks/${taskID.TaskID}`);
                taskRef.once("value", snapshot => {
                    let task = snapshot.val();
                    if(task.Status === "complete") {
                        let currentCompleteTasks = this.state.completedTasks;
                        currentCompleteTasks.push(task);
                        this.setState({completedTasks: currentCompleteTasks});
                    } else if(task.Status === "incomplete") {
                        let currentIncompleteTasks = this.state.incompleteTasks;
                        currentIncompleteTasks.push(task)
                        this.setState({incompleteTasks: currentIncompleteTasks})
                    }
                });
            });
        }
    }

    render() {
        return(
            this.props.activeCompletedGoal ?
            <Modal open={this.props.activeCompletedGoal ? true : false}>
                <i className="times circle icon modal-close" onClick={this.props.handleCloseGoalModal}></i>
                
                <h2 className="cg-heading">{this.props.activeCompletedGoal.Goal}</h2>
                <div className="cg-date">Created on {this.props.activeCompletedGoal.DateCreated}</div>
                <div className="cg-description">{this.props.activeCompletedGoal.Description}</div>
                
                <Menu pointing secondary color="blue">
                    <Menu.Item active={this.state.onMenu === "completed"} onClick={this.handleItemClick}>{`Completed (${this.state.completedTasks.length})`}</Menu.Item>
                    <Menu.Item active={this.state.onMenu === "incomplete"} onClick={this.handleItemClick}>{`Incomplete (${this.state.incompleteTasks.length})`}</Menu.Item>
                </Menu>
                {this.state.onMenu === "completed" ?
                    <div className="cg-tasks">
                        {this.state.completedTasks.length > 0 ?
                            this.state.completedTasks.map((task, index) => {
                                return(
                                    <Card fluid key={"ct" + index} className="cg-task">
                                        <Card.Content header={task.Task} />
                                        <Card.Content description={task.Description} />
                                        <Card.Content extra>
                                            by @{task.Creator}
                                        </Card.Content>
                                    </Card>
                                )
                            })
                            :
                            <p>No completed tasks to show</p>
                        }
                    </div>
                    :
                    <div className="cg-tasks">
                        {this.state.incompleteTasks.length > 0 ?
                            this.state.incompleteTasks.map((task, index) => {
                                return(
                                    <Card fluid key={"it" + index}>
                                        <Card.Content header={task.Task} />
                                        <Card.Content description={task.Description} />
                                        <Card.Content extra>
                                            by @{task.Creator}
                                        </Card.Content>
                                    </Card>
                                )
                            })
                            :
                            <p>No incomplete tasks to show</p>
                        }
                    </div>
                }
                {this.props.onOwnProfile ?
                    <Modal.Actions>
                        <Button primary onClick={this.handleReactivateGoal}>Reactivate Goal</Button>
                        <Button color="red" onClick={this.handleDeleteGoal}>Delete Goal</Button>
                    </Modal.Actions>
                    :
                    <div />
                }
            </Modal>
            :
            <div />
        );
    }

    handleItemClick = () => {
        let res = "";
        if(this.state.onMenu === "completed") {
            res = "incomplete"
        } else {
            res = "completed"
        }
        this.setState({
            onMenu: res
        });
    }

    handleReactivateGoal = () => {
        let goalRef = firebase.database().ref(`Goals/${this.props.activeGoalID}`);
        goalRef.update({
            IsComplete: false
        }).then(() => {
            this.props.handleCloseGoalModal();
        })
    }

    handleDeleteGoal = () => {
        this.deleteTasks();
        this.deleteGoal();
        this.deleteGoalFromUser();
        this.props.handleCloseGoalModal();
    }

    deleteTasks = () => {
        if (this.props.activeCompletedGoal.TaskList) {
            this.props.activeCompletedGoal.TaskList.forEach(task => {
                let taskRef = firebase.database().ref(`Tasks/${task.TaskID}`);
                taskRef.set(null);
            });
        }
    }

    deleteGoal = () => {
        let goalRef = firebase.database().ref(`Goals/${this.props.activeGoalID}`);
        goalRef.set(null);
    }

    deleteGoalFromUser = () => {
        let updatedGoals = this.props.currentUser.Goals.filter(goalID => {
            return goalID !== this.props.activeGoalID;
        });
        let userRef = firebase.database().ref(`Users/${this.props.currentUserID}`);
        userRef.update({
            Goals: updatedGoals
        });
    }

}