import React, { Component } from 'react';
import './GoalCard.css';

export default class GoalCard extends Component {
    render() {
        return (
            this.props.details ?
            <div className="ui card">
                <div className="content goal-click" onClick={this.handleGoalClick}>
                    <div className="header goal-header">
                        {this.props.details.Goal}
                    </div>
                    <div className="description goal-description">
                        {this.props.details.Description}
                    </div>

                    <div className="ui vertical segment" />
                    <div className="meta profile-meta-content">
                        Created on {this.props.details.DateCreated}
                    </div>
                    <p></p>
                </div>
            </div>
            :
            <div />
        );
    }

    handleGoalClick = e => {
        this.props.handleGoalClick(this.props.goalID);
    }
}