import React, { Component } from 'react';
import './SignUp.css';
import firebase from 'firebase';
import { withRouter } from 'react-router-dom';
import DEFAULT_PROFILE_IMAGE from '../images/DEFAULT_USER_IMAGE.jpg';


let laddrGoal = {
    DateCreated: "",
    Description: "Welcome to laddr! laddr is a collaborative career tracker tool built to better turn your goals into an actionable plan. Track your goals, create your own tasks, and share your progress with your community and supporters. Work through this goal to better understand what laddr offers. We're so glad you're here and ready to climb with us.",
    Goal: "Learn how to use laddr",
    IsComplete: false,
    Tags: "laddr, product, learning",
    UserId: ""
}

let laddrTasks = [
    {
        Creator: "laddr",
        Description: "Goal setting can be hard, but also how you set the goal up also matters. When setting goals, be sure to be SMART about it by following the SMART objectives. Goals should be Specific, Measurable, Attainable, Relevant, and Time-Orientated. Having a SMART goal will make it easier to better create action-orientated tasks that work toward achieving that goal! Get started by creating your first goal on laddr now. ",
        DueDate: "",
        Goal: "",
        Status: "incomplete",
        Task: "Create a goal",
        UserID: ""
    },
    {
        Creator: "laddr",
        Description: "Goals are big things to accomplish. Reaching a goal should be the result of action-orientated tasks. When you're creating your tasks, think about small actions you can take that can contribute to the larger goal. Remember, everyone had to start somewhere, and completing daunting goals are often the result of accumulated small actions. Get started by clicking \"Add Task\", in the new goal you just created.",
        DueDate: "",
        Goal: "",
        Status: "incomplete",
        Task: "Create a task",
        UserID: ""
    },
    {
        Creator: "laddr",
        Description: "When you feel you've completed a task be sure to check the \"Complete\" box. Doing so will mark that task as complete. When you're ready, feel free to click \"Complete\" on this task. Woohoo! One task done, and here's to even more progress you'll make with laddr!",
        DueDate: "",
        Goal: "",
        Status: "incomplete",
        Task: "Complete a task",
        UserID: ""
    },
    {
        Creator: "laddr",
        Description: "It's more fun to progress towards your goals when your community is involved. Find someone new to connect with on laddr in the \"Community\" tab or invite a peer or mentor to join you on laddr. Go to their profile page and hit \"Connect\" to mutually join communities. Having a strong community can provide you with support and encouragement while you pursue your goal.",
        DueDate: "",
        Goal: "",
        Status: "incomplete",
        Task: "Connect with someone on laddr",
        UserID: ""
    },
    {
        Creator: "laddr",
        Description: "The best part of laddr is helping someone else work towards their goal by suggesting tasks for them to take action on. It's a great way of sharing knowledge and supporting the progress of someone in your community. Go to someone's goal on laddr and suggest a task to help them get closer to achieving their goal.",
        DueDate: "",
        Goal: "",
        Status: "incomplete",
        Task: "Suggest a task for someone",
        UserID: ""
    },
    {
        Creator: "laddr",
        Description: "As you're working through tasks, you'll eventually feel that you might have completed your goal. This could be when you have 0 tasks upcoming or when you still have upcoming tasks in progress. What completing your goal means is dependent on you. You determine when you've completed your goal, and laddr is always ready for your next goals.",
        DueDate: "",
        Goal: "",
        Status: "incomplete",
        Task: "Complete a goal",
        UserID: ""
    }
]
  

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: {
                Privacy: "PUBLIC",
                FirstName: "",
                LastName: "",
                EmailAddress: "",
                Username: ""
            },
            password: "",
            resumeFile: null,
            profilePicFile: null,
            message: "",
            isLoading: false,
            usernames: {},
            emptyFieldError: false,
            usernameError: false,
        }
    }

    componentWillMount() {
        let usernamesRef = firebase.database().ref("Usernames");
        let componentRef = this;
        usernamesRef.on("value", function(snapshot) {
            if (snapshot && snapshot.val()) {
                componentRef.setState({
                    usernames: snapshot.val()
                });
            }
        });
    }

    // Updates the state as the user fills in the fields
    updateUserInfo(event) {
        var updatedUserInfo = this.state.userInfo;
        updatedUserInfo[event.target.name] = event.target.value;
        this.setState({
            userInfo: updatedUserInfo
        });
    }


    blobToFile(theBlob, fileName) {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }

    // Stores files (resume, profile pic) to firebase storage and stores the links to those files in
    // user database
    storeFiles(file, fileName, uid) {
        if (!file && fileName === "ProfilePic") {
            var componentRef = this;
            var canvas = document.createElement("canvas");
            var context = canvas.getContext('2d');
            var defaultImg = new Image();
            defaultImg.src = DEFAULT_PROFILE_IMAGE;
            defaultImg.onload = function() {
                canvas.width = 550;
                canvas.height =  550;
                context.drawImage(defaultImg, 0, 0, defaultImg.width, defaultImg.height, 0, 0, canvas.width, canvas.height);
                canvas.toBlob(function(blob) {
                    componentRef.storeFiles(componentRef.blobToFile(blob, "ProfilePic"), "ProfilePic", uid);
                }, "image/jpeg");
            }

        }

        if (file !== null) {
            var storageRef = firebase.storage().ref().child(uid + "/" + fileName);
            storageRef.put(file).then((snapshot) => {
                storageRef.getDownloadURL().then((url) => {
                    var userRef = firebase.database().ref("Users/" + uid + "/" + fileName);
                    userRef.set(url);
                });
            });
        }
    }

    // Creates new user and adds it to database
    createUser(email, password) {
        if (this.validateForm()) {
            firebase.auth().createUserWithEmailAndPassword(email, password).then((firebaseUser) => {
                let usernamesRef = firebase.database().ref("Usernames/" + this.state.userInfo["Username"]);
                usernamesRef.set(firebaseUser.user.uid);

                let userRef = firebase.database().ref("Users/" + firebaseUser.user.uid);

                // Add default laddr goal
                laddrGoal["UserId"] = firebaseUser.user.uid;
                var today = new Date();
                var day = today.getDate();
                var month = today.getMonth() + 1; // January is 0!
                var year = today.getFullYear();
                laddrGoal["DateCreated"] = `${day}/${month}/${year}`;

                // Update laddr goal with user info
                let goalsRef = firebase.database().ref("Goals");
                let goalKey = goalsRef.push().key;

                // Add tasks to goal
                let tasksRef = firebase.database().ref("Tasks");
                var taskIdList = []
                laddrTasks.forEach(function(task) {
                    task["Goal"] = goalKey;
                    task["UserID"] = firebaseUser.user.uid;
                    var taskKey = tasksRef.push(task).key;
                    taskIdList.push({
                        TaskID: taskKey,
                        DueDate: "",
                        Status: "incomplete"
                    });
                });
                laddrGoal["TaskList"] = taskIdList;
                goalsRef.child(goalKey).set(laddrGoal);

                // Add goal reference to user schema
                let userInfo = this.state.userInfo;
                userInfo["Goals"] = []
                userInfo["Goals"].push(goalKey);

                userRef.set(userInfo, (error) => {
                    if (!error) {
                        this.storeFiles(this.state.resumeFile, "Resume", firebaseUser.user.uid);
                        this.storeFiles(this.state.profilePicFile, "ProfilePic", firebaseUser.user.uid);
                    }
                }).then(() => {
                    let currentUserRef = firebase.database().ref(`Users/${firebaseUser.user.uid}`);
                    currentUserRef.once("value", snapshot => {
                        let user = snapshot.val();
                        this.props.history.push(`/profile/${user.Username}`);
                    });
                })

            }).catch((error) => {
                this.setState({
                    message: "Error: " + error.message,
                    isLoading: false
                });
            });
        }
    }

    // Checks that fields on the sign-up form are valid
    validateForm() {
        var prohibitedChars = /[\.\$\[\]\#\/ ]/;
        if (!this.state.userInfo.EmailAddress || !this.state.password || 
            !this.state.userInfo.FirstName || !this.state.userInfo.LastName || !this.state.userInfo.Username) {
            this.setState({
                message: "Please fill out all required fields.",
                emptyFieldError: true,
                isLoading: false
            });
            return false;
        } else if (this.state.usernames[this.state.userInfo.Username]) {
            this.setState({
                message: "That username is already taken.",
                usernameError: true,
                isLoading: false
            });
            return false;
        } else if (prohibitedChars.test(this.state.userInfo.Username)) {
            this.setState({
                message: "Usernames cannot contain spaces or the characters: . $ [ ] # /",
                usernameError: true,
                isLoading: false
            });
            return false;
        }
        this.setState({
            takenUsernameError: false,
            emptyFieldError: false
        });
        return true;
    }
    
    render() {
        var signUpButtonClass = "fluid primary ui button";
        if (this.state.isLoading) {
            signUpButtonClass = signUpButtonClass + " loading"
        }

        var emailFieldClass = "required field";
        var passwordFieldClass = "required field";
        var firstNameFieldClass = "required field";
        var lastNameFieldClass = "required field";
        var usernameFieldClass = "required field";


        if (this.state.emptyFieldError) {
            if (!this.state.userInfo.EmailAddress) {
                emailFieldClass = emailFieldClass + " error"
            }
            if (!this.state.password) {
                passwordFieldClass = passwordFieldClass + " error"
            }
            if (!this.state.userInfo.FirstName) {
                firstNameFieldClass = firstNameFieldClass + " error"
            }
            if (!this.state.userInfo.LastName) {
                lastNameFieldClass = lastNameFieldClass + " error"
            }
            if (!this.state.userInfo.Username) {
                usernameFieldClass = usernameFieldClass + " error"
            }
        }

        if (this.state.usernameError) {
            usernameFieldClass = usernameFieldClass + " error"
        }

        return(
            <div className="sign-up">
                <h2>Join Us</h2>
                <div className="ui form">
                    <div className={emailFieldClass}>
                        <label>Email</label>
                        <div className="ui icon input">
                            <input type="email" name="EmailAddress" placeholder="Email" onChange={(e) => this.updateUserInfo(e)} />
                            <i className="envelope icon"></i>
                        </div>
                    </div>
                    <div className={passwordFieldClass}>
                        <label>Password</label>
                        <div className="ui icon input">
                            <input type="password" name="Password" placeholder="Password" onChange={(e) => this.setState({password: e.target.value})} />
                            <i className="key icon"></i>
                        </div>
                    </div>
                    <div className={usernameFieldClass}>
                        <label>Username</label>
                        <div className="field ui icon input">
                            <input type="text" name="Username" placeholder="Username" onChange={(e) => this.updateUserInfo(e)} />
                            <i className="user icon"></i>
                        </div>
                    </div>
                    <div className={firstNameFieldClass}>
                        <label>First name</label>
                        <div className="ui icon input">
                            <input type="text" name="FirstName" placeholder="First Name" onChange={(e) => this.updateUserInfo(e)} />
                            <i className="user icon"></i>
                        </div>
                    </div>
                    <div className={lastNameFieldClass}>
                        <label>Last name</label>
                        <div className="ui icon input">
                            <input type="text" name="LastName" placeholder="Last Name" onChange={(e) => this.updateUserInfo(e)} />
                            <i className="user icon"></i>
                        </div>
                    </div>
                    
                    <div class="ui hidden divider"></div>
                    <div className="field">
                        <label>Profile Picture</label>
                        <input type="file" name="ProfilePic" accept="image/*" onChange={(e) => this.setState({profilePicFile: e.target.files[0]})} />
                    </div>              
                    <div className="field">
                        <label>School name</label>
                        <input type="text" name="School" placeholder="School Name" onChange={(e) => this.updateUserInfo(e)} />
                    </div>
                    <div className="field">
                        <label>Career of Interest</label>
                        <input type="text" name="CareerOfInterest" placeholder="Career of Interest" onChange={(e) => this.updateUserInfo(e)} />
                    </div>
                    <div className="field">
                        <label>Resume</label>
                        <input type="file" name="Resume" accept=".pdf" onChange={(e) => this.setState({resumeFile: e.target.files[0]})} />
                     </div>
                     <button className={signUpButtonClass} type="submit" onClick={() => {
                        this.setState({isLoading: true});
                        this.createUser(this.state.userInfo.EmailAddress, this.state.password);
                     }}>Sign Up</button>
                     <p className="member-msg">Already a member? <span className="login-link" onClick={() => this.props.switchState("log-in")}>Log in</span></p>
                     {(this.state.message !== "") && <p className="ui negative message">{this.state.message}</p>}
                </div>
            </div>
        );
    }
}


export default withRouter(SignUp);