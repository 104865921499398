import React, { Component } from 'react';
import './ProfileLeft.css';

let DEFAULT_PROFILE_PIC = "http://clipart-library.com/images/ATbrxjpyc.jpg";

export default class EditProfileCard extends Component {

    constructor(props) {
        super(props);
        this.privacyStatus = this.props.privacyStatus;
    }

    render() {
        return(

            <div className="ui card">

                <div className="image">
                    <img src={this.props.profilePic || DEFAULT_PROFILE_PIC} alt="" />
                </div>

                <div className="content">

                    <h5 className="profile-username">@{this.props.username}</h5>

                    <div className="ui input fluid">
                        <input type="file" onChange={this.props.handlePicSelect}/>
                    </div>

                    <div className="ui input fluid profile-form">
                        <input type="text" placeholder={this.props.name ? this.props.name : "name"} onChange={e => this.props.handleNameChange(e.target.value)} />
                    </div>

                    <div className="ui input fluid profile-form">
                        <input type="text" placeholder={this.props.careerOfInterest ? this.props.careerOfInterest : "Industry that interests you"} onChange={e => this.props.handleCareerChange(e.target.value)}/>
                    </div>

                    <div className="ui form profile-form">
                        <div className="field">
                            <textarea value={this.props.description} rows={5} onChange={e => this.props.handleDescriptionChange(e.target.value)} placeholder={this.props.description ? this.props.description : "Tell people something about yourself"}/>
                        </div>
                    </div>

                    <div className="ui form profile-form">
                        <div className="field">
                            <textarea value={this.props.tags} rows={3} onChange={e => this.props.handleTagsChange(e.target.value)} placeholder={this.props.tags ? this.props.tags : "Add a comma separated list of your skills"}/>
                        </div>
                    </div>

                    <div className="ui labeled input fluid profile-form">
                        <div className="ui label">
                            <i className="github icon" />
                        </div>
                        <input type="text" placeholder={this.props.github ? this.props.github : "Github profile link"} onChange={e => this.props.handleGithubChange(e.target.value)} defaultValue="https://www.github.com/"/>
                    </div>

                    <div className="ui labeled input fluid profile-form">
                        <div className="ui label">
                            <i className="linkedin icon" />
                        </div>
                        <input type="text" placeholder={this.props.linkedin ? this.props.linkedin.linkedin : "Linkedin profile link"} onChange={e => this.props.handleLinkedinChange({linkedin: e.target.value})} defaultValue="https://www.linkedin.com/in/"/>
                    </div>
                    <div className="ui checkbox profile-form">
                        <input type="checkbox" name="example" onChange={this.handlePrivacyChange}/>
                        <label>Make my profile {this.privacyStatus === "PUBLIC" ? "private" : "public"}</label>
                    </div>

                </div>

                <div className="extra content">
                    <div className="ui labeled input fluid profile-form">
                        <div className="ui label">
                            <i className="envelope icon" />
                        </div>
                        <input type="text" placeholder={this.props.email ? this.props.email : "Email address"} onChange={e => this.props.handleEmailChange(e.target.value)}/>
                    </div>
                </div>

                {this.props.isEditing ?
                <div className="extra content option-link" onClick={this.props.handleSaveChanges}>
                    <i className="pencil icon" />
                    {this.props.saving ? "Saving..." : "Save Changes"}
                </div>
                :
                <div />
                }
                {this.props.isEditing ?
                <div className="extra content option-link" onClick={this.props.handleCancelClick}>
                    <i className="close icon" />
                    Cancel
                </div>
                :
                <div />
                }

            </div>

        )

    }

    handlePrivacyChange = () => {
        if(this.props.privacyStatus === "PUBLIC") {
            this.props.handlePrivacyChange("PRIVATE");
        } else {
            this.props.handlePrivacyChange("PUBLIC");
        }
    }

}