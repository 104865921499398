import React, { Component } from 'react';
import Navbar from '../../common/navbar/Navbar';
import "./PlanPage.css";
import GoalCard from "./GoalCard";
import GoalMenu from "./GoalMenu";
import TaskEditor from "./TaskEditor";
import GoalEditor from "./GoalEditor";
import TaskList from "./TaskList";
import firebase from "firebase";
import { Modal } from 'semantic-ui-react';
import TaskForm from "./TaskForm";
import { withRouter } from 'react-router-dom';

let modalStates = {
    taskEdit: "TASK_EDIT",
    taskAdd: "TASK_ADD",
    goalEdit: "GOAL_EDIT"
}

let DEFAULT_PROFILE_PIC = "http://clipart-library.com/images/ATbrxjpyc.jpg";

class PlanPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentUserId: "",
            currentUsername: "",
            otherUserId: "",
            otherUsername: "",
            goalList: {}, 
            activeGoal: "",
            firstName: "",
            lastName: "",
            modalOpen: false,
            editTask: {},
            editTaskId: "",
            editGoal: {},
            modalState: "",
            editTaskIndex: 0,
            incompleteTaskCount: 0,
            completeTaskCount: 0,
            suggestedTaskCount: 0,
            isLoading: true,
            profilePic: "",
            isPlanViewable: true,
            privacyStatus: "",
            community: []
        }
        this.taskInputRef = React.createRef();
    }

    // Gets plan data of logged in user
    componentWillMount() {
        let urlDetails = window.location.href.split("/plan/");
        
        let goalDetails = urlDetails[1].includes("?q=") ? urlDetails[1].split("?q=") : urlDetails[1];
        let otherUsername = goalDetails[0];
        let goalIndex = goalDetails[1] ? goalDetails[1] : "";

        this.setState({
            otherUsername: otherUsername,
            activeGoal: goalIndex
        });

        let userRef = firebase.database().ref(`Usernames/${otherUsername}`);
        let componentRef = this;
        userRef.once("value", function(userId) {
            var otherUserId = ""
            if (userId && userId.val() && typeof userId.val() === "string") {
                otherUserId = userId.val();
            }
            firebase.auth().onAuthStateChanged(function(currentUser) {
                if (currentUser) {
                  componentRef.setState({
                    currentUserId: currentUser.uid
                  });

                  if (otherUserId === currentUser.uid) {
                      otherUserId = ""
                  }
    
                  var activeUser = otherUserId ? otherUserId : currentUser.uid;
    
                  componentRef.getCurrentUserData(currentUser);
                  componentRef.getActiveUserData(activeUser, otherUserId, currentUser.uid);
    
                } else {
                  componentRef.props.history.push("/log-in")
                }
            });
        });
    }
    
    // Gets data of the currently logged in user
    getCurrentUserData(currentUser) {
        let componentRef = this;
        let currentUserRef = firebase.database().ref(`Users/${currentUser.uid}`);
              currentUserRef.on("value", function(snapshot) {
                    if (snapshot != null && snapshot.val() != null) {
                        var data = snapshot.val();
                        var username = data["Username"];
                        var community = data["Community"] || [];
                        componentRef.setState({
                            currentUsername: username,
                            community: community
                        });
                    }
              });
    }

    // Gets various data for the user whose plan page is displayed
    getActiveUserData(activeUser, otherUser, currentUser) {
        var componentRef = this;
        let databaseRef = firebase.database().ref("Users/" + activeUser);
        databaseRef.on("value", function(snapshot) { 
            if (snapshot && snapshot.val()) {
                var data = snapshot.val();
                var username = data["Username"];
                var fName = data["FirstName"];
                var lName = data["LastName"];
                var picture = data["ProfilePic"] || DEFAULT_PROFILE_PIC;
                var community = data["Community"] || [];
                var isPlanViewable = activeUser !== otherUser || (data["Privacy"] === "PUBLIC");
                
                var communitySize = community.length;
                var i = 0;
                while (i < communitySize && !isPlanViewable) {
                    if (community[i].uid === currentUser) {
                        isPlanViewable = true;
                    }
                    i++;
                }
                
                var goalIds = data["Goals"] || [];
                componentRef.getGoalsFromIds(goalIds);

                componentRef.setState({
                    isPlanViewable: isPlanViewable,
                    otherUserId: otherUser,
                    otherUsername: otherUser ? username : "",
                    firstName: fName,
                    lastName: lName,
                    profilePic: picture,
                    privacyStatus: data["Privacy"],
                    activeGoal: componentRef.state.activeGoal || goalIds[0] || ""
                });
            } else {
                componentRef.props.history.push("/plan");
                componentRef.getActiveUserData(componentRef.state.currentUserId, "");
            }
        });
    }

    // Takes a list of goal ids and gets a list of goal objects from the database
    getGoalsFromIds(goalIds) {
        let goalRef = firebase.database().ref("Goals");
        let componentRef = this;
        goalRef.on("value", function(snapshot) {
            let goals = {};
            if (snapshot && snapshot.val()) {
                var goalData = snapshot.val();
                goalIds.forEach(function(goalId) {
                    if (goalData[goalId] && !goalData[goalId].IsComplete) {
                        goals[goalId] = goalData[goalId];
                    }
                });
            }
            var activeGoal = Object.keys(goals).includes(componentRef.state.activeGoal) ? componentRef.state.activeGoal : (Object.keys(goals)[0] || "");

            var taskList = goals[activeGoal] ? 
                    goals[activeGoal].TaskList : [];

            var numComplete = 0;
            var numIncomplete = 0;
            var numSuggested = 0;
            if (taskList) {
                taskList.forEach(function(task) {
                    if (task.Status === "complete") {
                        numComplete = numComplete + 1;
                    } else if (task.Status === "incomplete") {
                        numIncomplete = numIncomplete + 1;
                    } else {
                        numSuggested = numSuggested + 1;
                    }
                });
            }
            componentRef.setState({
                completeTaskCount: numComplete,
                incompleteTaskCount: numIncomplete,
                suggestedTaskCount: numSuggested,
                isLoading: false,
                goalList: goals,
                activeGoal: activeGoal
            });
        });
        
    }

    // Opens up modal for editing task
    handleEditTask(taskNum, taskId) {
        this.setState({
            modalOpen: true,
            editTask: this.state.goalList[this.state.activeGoal].TaskList[taskNum],
            editTaskIndex: taskNum,
            editTaskId: taskId,
            modalState: modalStates.taskEdit
        });
    }


    // Displays goal editor
    handleEditGoal() {
        this.setState({
            modalOpen: true,
            modalState: modalStates.goalEdit,
            editGoal: this.state.goalList[this.state.activeGoal]
        });
    }


    // Changes goal status to complete
    completeGoal() {
        let databaseRef = firebase.database().ref(`Goals/${this.state.activeGoal}/IsComplete`);
        databaseRef.set(true);
        this.getGoalsFromIds(Object.keys(this.state.goalList));
    }

    // Updates task list when active goal is changed on goal menu
    handleMenuChange(goalId) {
        var taskList = this.state.goalList[goalId].TaskList || [];
        var numComplete = 0;
        var numIncomplete = 0;
        var numSuggested = 0;
        taskList.forEach(function(task) {
            if (task.Status === "complete") {
                numComplete = numComplete + 1;
            } else if (task.Status === "incomplete") {
                numIncomplete = numIncomplete + 1;
            } else {
                numSuggested = numSuggested + 1;
            }
        });

        this.setState({
            completeTaskCount: numComplete,
            incompleteTaskCount: numIncomplete,
            suggestedTaskCount: numSuggested,
            activeGoal: goalId
        });
    }

    // Displays task editor
    handleAddTask() {
        this.setState({
            modalOpen: true,
            modalState: modalStates.taskAdd
        });
    }
    render() {
        var suggestedTaskTitle = "New suggested tasks";
        if (this.state.otherUserId) {
            suggestedTaskTitle = "Pending suggested tasks";
        }
        return (
            <div className="plan-page">
                <Navbar />
                {this.state.isLoading ? <div className="ui active loader"></div> :
                <div className="plan-content">
                    <div className="plan-container">
                        <h1 className="plan-title">{this.state.otherUserId ? `${this.state.firstName}'s ` : "My "}plan</h1>
                    </div>
                    {this.state.isPlanViewable ?
                        <div>
                            <GoalMenu 
                                activeGoal={this.state.activeGoal} 
                                goalList={this.state.goalList}
                                currentUserId={this.state.currentUserId}
                                isOtherUser={this.state.otherUserId !== ""}
                                changeActiveGoal={(goalId) => this.handleMenuChange(goalId)} />

                            {Object.keys(this.state.goalList).length === 0 && !this.state.otherUserId &&
                                <div className="no-goal-msg">
                                    <p>No goals here yet. Be sure to add your first goal on laddr!</p>
                                    <button className="ui primary button" onClick={() => {
                                        this.setState({
                                            activeGoal: ""
                                        }, () => {
                                            this.handleEditGoal();
                                        });

                                    }}>Create a Goal</button>
                                </div>
                            }     
                            <div className="plan-container">
                                <div className="page-content">
                                <div className="goal-container">
                                        {this.state.otherUserId && Object.keys(this.state.goalList).length > 0 &&
                                            <button className="ui fluid button primary"
                                            onClick={() => this.handleAddTask()}>Suggest Task</button>
                                        }
                                        {Object.keys(this.state.goalList).length > 0 && this.state.goalList[this.state.activeGoal] &&
                                            <GoalCard 
                                                goal={this.state.goalList[this.state.activeGoal].Goal} 
                                                description={this.state.goalList[this.state.activeGoal].Description} 
                                                dateCreated={this.state.goalList[this.state.activeGoal].DateCreated}
                                                tags={this.state.goalList[this.state.activeGoal].Tags} 
                                                firstName={this.state.firstName}
                                                lastName={this.state.lastName}
                                                otherUsername={this.state.otherUsername}
                                                isOtherUser={this.state.otherUserId !== ""}
                                                editGoal={() => this.handleEditGoal()}
                                                profilePic={this.state.profilePic}
                                            />
                                        }
                                        {this.state.otherUserId === "" && Object.keys(this.state.goalList).length > 0  && 
                                        this.state.goalList[this.state.activeGoal] &&
                                        <div>
                                            <button className="ui primary button fluid" onClick={() => this.handleAddTask()}>Add Task</button>
                                            <p className="complete-goal-btn" onClick={() => this.completeGoal()}>Complete goal</p>
                                        </div>}
                                    </div>

                                    <div className="task-lists">
                                        {(this.state.suggestedTaskCount > 0) && this.state.goalList[this.state.activeGoal] != undefined && 
                                            <TaskList
                                                title={suggestedTaskTitle}
                                                taskCount={this.state.suggestedTaskCount}
                                                currTaskList={this.state.goalList[this.state.activeGoal].TaskList}
                                                currentUserId={this.state.currentUserId}
                                                currentUsername={this.state.currentUsername}
                                                isOtherUser={this.state.otherUserId !== ""}
                                                activeGoal={this.state.activeGoal}
                                                taskStatus="suggested"
                                                privacyStatus={this.state.privacyStatus}
                                                community={this.state.community}
                                                editTask={(taskNum, taskId) => this.handleEditTask(taskNum, taskId)} />
                                        }
                                        {(this.state.incompleteTaskCount > 0) && 
                                            <TaskList
                                                title={"Your Upcoming Tasks"}
                                                taskCount={this.state.incompleteTaskCount}
                                                currTaskList={this.state.goalList[this.state.activeGoal].TaskList}
                                                taskStatus="incomplete"
                                                isOtherUser={this.state.otherUserId !== ""}
                                                currentUserId={this.state.currentUserId}
                                                currentUsername={this.state.currentUsername}
                                                activeGoal={this.state.activeGoal}
                                                privacyStatus={this.state.privacyStatus}
                                                community={this.state.community}
                                                editTask={(taskNum, taskId) => this.handleEditTask(taskNum, taskId)} />
                                        }
                                        {(this.state.completeTaskCount > 0) && 
                                            <TaskList
                                                title={"Completed Tasks"}
                                                taskCount={this.state.completeTaskCount}
                                                currTaskList={this.state.goalList[this.state.activeGoal].TaskList}
                                                isOtherUser={this.state.otherUserId !== ""}
                                                currentUserId={this.state.currentUserId}
                                                currentUsername={this.state.currentUsername}
                                                activeGoal={this.state.activeGoal}
                                                taskStatus="complete"
                                                privacyStatus={this.state.privacyStatus}
                                                community={this.state.community}
                                                editTask={(taskNum, taskId) => this.handleEditTask(taskNum, taskId)} />
                                        }
                                        {(this.state.goalList[this.state.activeGoal] && !this.state.goalList[this.state.activeGoal].TaskList && !this.state.otherUserId) &&
                                            <div className="no-task-msg">
                                                <p>No tasks here yet. Be sure to add your first task to work towards completing your goal!</p>
                                                <button className="ui primary button" onClick={() => this.handleAddTask()}>Add Task</button>

                                            </div>
                                        }
                                    </div>
                                    
                                </div>
                            </div>
                        </div> : 
                        <div className="privacy-msg">
                            <p>{this.state.firstName}'s plan is currently private. Become a supporter of {this.state.firstName} to view it!</p>
                        </div>
                    }
                </div>}
                <Modal open={this.state.modalOpen} className="modal" size="small">
                    <i className="times circle icon modal-close" 
                        onClick={() => this.setState({modalOpen: false})}></i>
                    {(this.state.modalState === modalStates.taskEdit) && 
                        <TaskEditor 
                            task={this.state.editTask}
                            currentUserId={this.state.currentUserId}
                            currTaskList={this.state.goalList[this.state.activeGoal].TaskList}
                            activeGoal={this.state.activeGoal}
                            taskNum={this.state.editTaskIndex}
                            taskId={this.state.editTaskId}
                            closeModal={() => this.setState({modalOpen: false})} />
                    }
                    {(this.state.modalState === modalStates.goalEdit) && 
                        <GoalEditor 
                            goal={this.state.editGoal}
                            saveGoal={(goal) => this.saveGoalEdit(goal)}
                            currentUserId={this.state.currentUserId}
                            activeGoal={this.state.activeGoal}
                            changeActiveGoal={(goalId) => this.handleMenuChange(goalId)}
                            activateDeleteGoalConfirmation={() => this.setState({
                                modalOpen: true,
                                modalState: modalStates.goalDelete
                            })}
                            setActiveGoal={() => this.setState({

                            })}
                            closeModal={() => this.setState({modalOpen: false})} />
                    }
                    {(this.state.modalState === modalStates.taskAdd) && 
                        <TaskForm 
                            isOtherUser={this.state.otherUserId !== ""}
                            privacyStatus={this.state.privacyStatus}
                            creator={this.state.currentUsername}
                            currTaskList={this.state.goalList[this.state.activeGoal].TaskList}
                            otherUserId={this.state.otherUserId}
                            currentUserId={this.state.currentUserId}
                            activeGoal={this.state.activeGoal}
                            community={this.state.community}
                            closeModal={() => this.setState({modalOpen: false})} />
                    }
                </Modal>
            </div>
        );
    }
}

export default withRouter(PlanPage);