import React, { Component } from 'react';
import "./ModalForms.css";
import firebase from "firebase";

let MAX_TASKS_ON_FEED = 100;

class TaskForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            task: {
                Task: "",
                Status: this.props.isOtherUser ? "suggested" : "incomplete",
                Description: "",
                DueDate: "",
                Creator: this.props.creator,
                Goal: this.props.activeGoal,
                UserID: this.props.isOtherUser ? this.props.otherUserId : this.props.currentUserId
            },
            emptyTask: false,
            errorMessage: ""
        }
    }

    // Updates state as task details are added
    handleAddTaskFieldChange(e) {
        var key = e.target.name;
        var value = e.target.value;
        var updatedTask = this.state.task;
        updatedTask[key] = value;
        this.setState({
            task: updatedTask
        });
    }

     // Adds new task to the database
     addTask() {
        if (this.state.task.Task) {

            // Add task to task branch
            let tasksRef = firebase.database().ref("Tasks");
            let taskPushKey = tasksRef.push(this.state.task).key;


            // Add task reference to goal branch
            let goalTasksRef = firebase.database().ref(`Goals/${this.props.activeGoal}/TaskList`);
            var taskList = this.props.currTaskList || [];
            var task = {
                TaskID: taskPushKey,
                DueDate: this.state.task.DueDate,
                Status: this.state.task.Status
            }
            if (task.DueDate) {
                goalTasksRef.once("value", function(snapshot) {
                    var oldTaskList = snapshot ? (snapshot.val() || []) : [];
                    var taskDate = new Date(task.DueDate);
                    var numberOfTasks = oldTaskList.length;
                    var itemAdded = false;
                    for (var i = 0; i < numberOfTasks; i++) {
                        var taskItemDate = new Date(oldTaskList[i].DueDate);
                        if ((taskDate <= taskItemDate || !oldTaskList[i].DueDate) && !itemAdded) {
                            oldTaskList.splice(i, 0, task);
                            itemAdded = true
                        }
                    }
                    if (oldTaskList.length === 0 || !itemAdded) {
                        oldTaskList.push(task);
                    }
                    goalTasksRef.set(oldTaskList);
                });
            } else {
                taskList.push(task);
                goalTasksRef.set(taskList);
            }

            // Add task to public feed
            if (this.props.privacyStatus === "PUBLIC") {
                let publicFeedRef = firebase.database().ref(`Public Feed`);
                publicFeedRef.once("value", function(snapshot) {
                    var publicFeed = snapshot ? (snapshot.val() || {}) : {};
                    if (Object.keys(publicFeed).length >= MAX_TASKS_ON_FEED) {
                        var taskToBeRemoved = Object.keys(publicFeed)[0]
                        publicFeed[taskToBeRemoved] = null;
                        publicFeedRef.set(publicFeed);
                    }
                    var taskFeedId = publicFeedRef.push({
                        TaskID: taskPushKey,
                        Type: "CREATED"
                    }).key;

                    // Add feed ID to task data
                    let taskFeedIdRef = firebase.database().ref(`Tasks/${taskPushKey}/PublicFeedIDs`);
                    taskFeedIdRef.once("value", function(snapshot) {
                        var taskFeedIdList = snapshot ? (snapshot.val() || []) : [];
                        taskFeedIdList.push(taskFeedId);
                        taskFeedIdRef.set(taskFeedIdList);
                    });
                });
            }

            // Add task to each supporter's community feed
            let communityRef = firebase.database().ref(`Users/${this.props.currentUserId}/Community`);
            communityRef.once("value", function(snapshot) {
                var community = snapshot ? (snapshot.val() || []) : [];
                var feedKey = "";
                var communityFeedTask = {
                    TaskID: taskPushKey,
                    Type: "CREATED"
                }
                community.forEach(function(communityObj) {
                    var supporterId = communityObj.uid;
                    let supporterFeedRef = firebase.database().ref(`Users/${supporterId}/CommunityFeed`);
                    supporterFeedRef.once("value", function(snapshot) {
                        var communityFeed = snapshot ? (snapshot.val() || {}) : {};
                        if (Object.keys(communityFeed).length >= MAX_TASKS_ON_FEED) {
                            var taskToBeRemoved = Object.keys(communityFeed)[0]
                            communityFeed[taskToBeRemoved] = null;
                            supporterFeedRef.set(communityFeed);
                        }
                        if (!feedKey) {
                            feedKey = supporterFeedRef.push(communityFeedTask).key;
                        } else {
                            supporterFeedRef.child(feedKey).set(communityFeedTask);
                        }
                    });
                });
                let taskFeedCommunityIDsRef = firebase.database().ref(`Tasks/${taskPushKey}/CommunityFeedIDs`);
                taskFeedCommunityIDsRef.once("value", function(snapshot) {
                    var taskFeedIdList = snapshot ? (snapshot.val() || []) : [];
                        taskFeedIdList.push(feedKey);
                        taskFeedCommunityIDsRef.set(taskFeedIdList);
                });
            });

            this.props.closeModal();
        } else {
            this.setState({
                emptyTask: true,
                errorMessage: "Be sure to add a title before adding tasks!"
            });
        }
    }


    render() {
        var taskClass = "field";
        if (this.state.emptyTask) {
            taskClass = taskClass + " error";
        }

        return(
            <div>
                <h2>{this.props.isOtherUser ? "Suggest" : "Add"} a task</h2>
                <div className="ui form modal-form">
                    <div className={taskClass}>
                        <input type="text" 
                            name="Task" 
                            placeholder="Title" 
                            onChange={(e) => this.handleAddTaskFieldChange(e)}
                            value={this.state.task.Task} /> 
                    </div>
                    <div className="field">
                        <textarea name="Description"
                            value={this.state.task.Description} 
                            onChange={(e) => this.handleAddTaskFieldChange(e)}
                            placeholder="Description">
                        </textarea>
                    </div>
                    <div className="eight wide field">
                        <div className="ui calendar" id="example1">
                            <div className="ui input left icon">
                                <i className="calendar icon"></i>
                                <input 
                                    type="text"  
                                    required="required"
                                    name="DueDate"
                                    placeholder="Due date" 
                                    onChange={(e) => this.handleAddTaskFieldChange(e)}
                                    onFocus={(e) => {
                                        e.target.type = "date"
                                    }} 
                                    onBlur={(e) => {
                                        e.target.type = "text"
                                    }}/>
                            </div>
                        </div>
                    </div>
                    <div className="field">
                        <button className="ui fluid button primary"
                             onClick={() => this.addTask()}>
                             {this.props.isOtherUser ? "Suggest" : "Add"} task
                        </button>
                    </div>
                    {this.state.errorMessage &&
                        <div className="ui negative message error-message">
                            <p>{this.state.errorMessage}</p>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default TaskForm;