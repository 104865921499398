import React, { Component } from 'react';
import { Card, Checkbox } from 'semantic-ui-react';
import "./TaskCard.css";
import firebase from "firebase";
import { withRouter, Link } from 'react-router-dom';

let DEFAULT_PROFILE_PIC = "http://clipart-library.com/images/ATbrxjpyc.jpg";
let MAX_TASKS_ON_FEED = 100;

class TaskCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            taskStatus: false,
            creatorPic: DEFAULT_PROFILE_PIC
        }
    }

    componentWillReceiveProps(updatedProps) {
        this.getCreatorInfo(updatedProps.creator, updatedProps.currentUsername);
    }

    getCreatorInfo(creator, currentUsername) {
        let componentRef = this;
        if (creator !== currentUsername) {
            let creatorIdRef = firebase.database().ref(`Usernames/${creator}`);
            creatorIdRef.once("value", function(snapshot) {
                if (snapshot && snapshot.val()) {
                    var creatorId = snapshot.val();
                    var profilePicRef = firebase.database().ref(`Users/${creatorId}/ProfilePic`);
                    profilePicRef.once("value", function(snapshot2) {
                        if (snapshot2 && snapshot2.val()) {
                            componentRef.setState({
                                creatorPic: snapshot2.val()
                            });
                        }
                    });
                }
            });
        }
    }

    componentWillMount() {
        this.setState({
            taskStatus: this.props.complete
        });
        this.getCreatorInfo(this.props.creator, this.props.currentUsername);
    }

    handleCheckBoxChange() {
        var newStatus = "";
        if (!this.state.taskStatus) {
            newStatus = "complete";
        } else {
            newStatus = "incomplete";
        }
        this.setState({
            taskStatus: !this.state.taskStatus
        }, () => {
            let taskStatusRef = firebase.database().ref(`Tasks/${this.props.taskId}/Status`);
            taskStatusRef.set(newStatus);

            let goalTaskStatusRef = firebase.database().ref(`Goals/${this.props.activeGoal}/TaskList/${this.props.taskNumber}/Status`);
            goalTaskStatusRef.set(newStatus);

            // Add complete task to public feed
            var componentRef = this;
            if (newStatus === "complete" && this.props.privacyStatus === "PUBLIC") {
                let publicFeedRef = firebase.database().ref(`Public Feed`);
                publicFeedRef.once("value", function(snapshot) {
                    var publicFeed = snapshot ? (snapshot.val() || {}) : {};
                    if (Object.keys(publicFeed).length >= MAX_TASKS_ON_FEED) {
                        var taskToBeRemoved = Object.keys(publicFeed)[0]
                        publicFeed[taskToBeRemoved] = null;
                        publicFeedRef.set(publicFeed);
                    }
                    var feedPushKey = publicFeedRef.push({
                        TaskID: componentRef.props.taskId,
                        Type: "COMPLETED"
                    }).key;
                    // Add feed push key to task data
                    let taskRef = firebase.database().ref(`Tasks/${componentRef.props.taskId}/FeedIDs`);
                    taskRef.once("value", function(snapshot) {
                        var taskFeedIdList = snapshot ? (snapshot.val() || []) : [];
                        taskFeedIdList.push(feedPushKey);
                        taskRef.set(taskFeedIdList);
                    });
                });
            }

            // Add complete goal to community feeds
            let communityRef = firebase.database().ref(`Users/${this.props.currentUserId}/Community`);
            communityRef.once("value", function(snapshot) {
                var community = snapshot ? (snapshot.val() || []) : [];
                var feedKey = "";
                var communityFeedTask = {
                    TaskID: componentRef.props.taskId,
                    Type: "COMPLETED"
                }
                community.forEach(function(communityObj) {
                    var supporterId = communityObj.uid;
                    let supporterFeedRef = firebase.database().ref(`Users/${supporterId}/CommunityFeed`);
                    supporterFeedRef.once("value", function(snapshot) {
                        var communityFeed = snapshot ? (snapshot.val() || {}) : {};
                        if (Object.keys(communityFeed).length >= MAX_TASKS_ON_FEED) {
                            var taskToBeRemoved = Object.keys(communityFeed)[0]
                            communityFeed[taskToBeRemoved] = null;
                            supporterFeedRef.set(communityFeed);
                        }
                        if (!feedKey) {
                            feedKey = supporterFeedRef.push(communityFeedTask).key;
                        } else {
                            supporterFeedRef.child(feedKey).set(communityFeedTask);
                        }
                    });
                });
                let taskFeedCommunityIDsRef = firebase.database().ref(`Tasks/${componentRef.props.taskId}/CommunityFeedIDs`);
                taskFeedCommunityIDsRef.once("value", function(snapshot) {
                    var taskFeedIdList = snapshot ? (snapshot.val() || []) : [];
                        taskFeedIdList.push(feedKey);
                        taskFeedCommunityIDsRef.set(taskFeedIdList);
                });
            }); 

        });
    }

    handleAcceptSuggestedTask() {
        let taskStatusRef = firebase.database().ref(`Tasks/${this.props.taskId}/Status`);
        taskStatusRef.set("incomplete");

        let goalTaskStatusRef = firebase.database().ref(`Goals/${this.props.activeGoal}/TaskList/${this.props.taskNumber}/Status`);
        goalTaskStatusRef.set("incomplete");
    }

    handleDeleteTask() {
        // Delete task reference from goal branch
        let goalTaskRef = firebase.database().ref(`Goals/${this.props.activeGoal}/TaskList`);
        var componentRef = this;
        goalTaskRef.once("value", function(snapshot) {
            if (snapshot && snapshot.val()) {
                var tasks = snapshot.val();
                tasks.splice(componentRef.props.taskNumber, 1);
                goalTaskRef.set(tasks);
            }
        });

        // Delete task reference from public feed
        let taskRef = firebase.database().ref(`Tasks/${this.props.taskId}`);
        taskRef.once("value", function(snapshot) {
            if (snapshot && snapshot.val()) {
                var data = snapshot.val();
                var feedIDs = data["PublicFeedIDs"] || [];
                feedIDs.forEach(function(id) {
                    let feedRef = firebase.database().ref(`Public Feed/${id}`);
                    feedRef.set(null);
                });
            }
            // Delete task reference from task branch
            taskRef.set(null);
        });

    }

    isOverdue() {
        if (this.props.dueDate) {
            var today = new Date();
            var deadline = new Date(this.props.dueDate);
            if (today > deadline) {
                return true;
            }
        }
        return false;
    }

    render() {
        var cardClass = "task-card";
        if (this.props.complete) {
            cardClass = cardClass + " completed-task"
        }

        var dueDateClass = "";
        if (this.props.taskStatus === "incomplete" && this.isOverdue()) {
            dueDateClass = "overdue";
        }

        var profileLink = "/profile"
        if (this.props.creator) {
            profileLink = profileLink + "/" + this.props.creator
        }

        return(
            <Card fluid className={cardClass}>
                <Card.Content>
                    {((this.props.taskStatus === "incomplete" && !this.props.isOtherUser) || (this.props.creator === this.props.currentUsername && this.props.isOtherUser && this.props.taskStatus === "suggested")) && 
                        <p className="action-btn gray-txt" onClick={() => this.props.editTask(this.props.taskNumber, this.props.taskId)}>Edit</p>}
                     {(this.props.taskStatus !== "incomplete" && !this.props.isOtherUser) && 
                        <i className="times icon action-btn" onClick={() => this.handleDeleteTask()}></i>}
                    <Card.Header>{this.props.task}</Card.Header>
                    <Card.Description>{this.props.description}</Card.Description>
                </Card.Content>
                <Card.Content extra>
                    {(this.props.taskStatus === "suggested" && !this.props.isOtherUser) && 
                        <button className="ui tiny green button bottom-right-element"
                            onClick={() => this.handleAcceptSuggestedTask()}>Accept</button>} 
                    {(this.props.taskStatus !== "suggested" && !this.props.isOtherUser) && 
                        <Checkbox className="bottom-right-element" defaultChecked={this.state.taskStatus} label='Complete' onChange={() => this.handleCheckBoxChange()} />}
                    <p>
                        {(this.props.creator !== this.props.currentUsername) &&
                            <img className="ui avatar image" src={this.state.creatorPic} />
                        }
                        {(this.props.taskStatus === "suggested") && "Suggested by "}
                        <strong className="black-txt">
                            {(this.props.creator === this.props.currentUsername) ? 
                                "me " :
                                <Link to={profileLink}>@{this.props.creator}</Link>
                            }
                        </strong>
                        {(this.props.taskStatus !== "suggested") && this.props.dueDate &&
                            <span className={dueDateClass}>Due on {this.props.dueDate}</span>}</p>
                </Card.Content>
            </Card>
        );
    }
}

export default TaskCard;



