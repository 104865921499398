import React, { Component } from 'react';
import './Navbar.css';
import { withRouter, Link } from 'react-router-dom';
import firebase from "firebase";

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
            username: "",
            usernameToSearch: ""
        }
    }


    componentDidMount() {
        firebase.auth().onAuthStateChanged(currentUser => {
            if (currentUser) {
                let currentUserRef = firebase.database().ref(`Users/${currentUser.uid}`);
                currentUserRef.once("value", snapshot => {
                    let user = snapshot.val();
                    this.setState({
                        isLoggedIn: true,
                        username: user.Username
                    });
                })
            } else {
                this.setState({
                    isLoggedIn: false
                });
            }
        });
    }

    signOutUser() {
        firebase.auth().signOut()
        .catch(error => {
            console.error('Sign Out Error', error);
        });
    }

    render() {

        return (
            <div className="ui secondary menu navbar-container">
                <h3 className="navbar-logo"><a href="/">laddr.</a></h3>
                <div className="right menu outer">
                
                    <div className="ui small action input search-bar inner">
                        <input type="text" placeholder="Find users..." value={this.state.usernameToSearch} onChange={e => this.setState({usernameToSearch: e.target.value})} onKeyDown={e => this.handleEnterClick(e)}/>
                        <div className="ui small button navbar-search-button" onClick={this.handleSearch}><i className="search icon" /></div>
                    </div>
                    <div className="mobile-layout inner">
                    <Link to={this.state.username ? `/plan/${this.state.username}` : ""} className="item">Plan</Link>
                    <Link to="/community" className="item">Community</Link>
                    {this.state.username ?
                        window.location.pathname.match("/profile*") ?
                            <div className="item navbar-button" onClick={this.handleProfileClick}>Profile</div>
                            :
                            <Link to={`/profile/${this.state.username}`} className="item">Profile</Link>
                        :
                        <div />
                    }
                    {this.state.isLoggedIn ?
                        <div className="item navbar-button" onClick={this.signOutUser}>Sign out</div>
                        :
                        <Link to={`/profile/${this.state.username}`} className="item">Sign in</Link>
                    }
                    </div>
                </div>
            </div>
        );
    }

    handleEnterClick = (e) => {
        if(e.keyCode === 13) {
            this.handleSearch();
        }
    }

    handleSearch = () => {
        if(this.state.usernameToSearch) {
            if(this.props.updateUrlUsername) {
                this.props.updateUrlUsername(this.state.usernameToSearch);
            } else {
                this.props.history.push(`/profile/${this.state.usernameToSearch}`);
            }
        }
        this.setState({usernameToSearch: ""});
        if(this.props.handleCancelClick) {
            this.props.handleCancelClick();
        }
    }

    handleProfileClick = () => {
        this.props.updateUrlUsername(this.state.username);
    }

}

export default withRouter(Navbar);