import React, { Component } from 'react';
import firebase from 'firebase';
import './ProfileLeft.css';
import EditProfileCard from './EditProfileCard';
import ProfileCard from './ProfileCard';

export default class ProfileLeft extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            name: "",
            careerOfInterest: "",
            description: "",
            tags: "",
            github: "",
            linkedin: "",
            email: "",
            privacyStatus: "",
            newProfilePicture: null
        };
        this.picRef = React.createRef();
    }

    componentDidMount() {
        this.setProfilePicture(this.props.urlUserID);
        this.setState({
            firstName: this.props.urlUser.FirstName,
            lastName: this.props.urlUser.LastName,
            name: this.props.urlUser.FirstName + " " + this.props.urlUser.LastName,
            careerOfInterest: this.props.urlUser ? this.props.urlUser.CareerOfInterest : "",
            description: this.props.urlUser.Description,
            tags: this.props.urlUser.Tags ? this.props.urlUser.Tags.join(","): "",
            github: this.props.urlUser.Github,
            linkedin: this.props.urlUser.Linkedin,
            privacyStatus: this.props.currentUser.Privacy,
            email: this.props.urlUser.EmailAddress,
            onOwnProfile: this.props.onOwnProfile,
            saving: false
        });
    }

    componentWillReceiveProps(updatedProps) {
        if(updatedProps.urlUserID !== this.props.urlUserID) {
            this.setProfilePicture(updatedProps.urlUserID);
        }
    }

    setProfilePicture = (userID) => {
        let storageRef = firebase.storage().ref().child(`${userID}/ProfilePic`);
        storageRef.getDownloadURL().then(url => {
            this.setState({profilePic: url});
        });
    }

    render() {
        return (
            !this.props.isEditing ?
            <ProfileCard
                onOwnProfile={this.props.onOwnProfile}
                alreadyRequestedUser={this.props.alreadyRequestedUser}
                otherUserRequested={this.props.otherUserRequested}
                isEditing={this.props.isEditing}
                currentUser={this.props.currentUser}
                currentUserID={this.props.currentUserID}
                urlUser={this.props.urlUser}
                urlUserID={this.props.urlUserID}
                
                profilePic={this.state.profilePic}
                username={this.props.urlUser.Username}
                firstName={this.props.urlUser.FirstName}
                lastName={this.props.urlUser.LastName}
                careerOfInterest={this.props.urlUser.CareerOfInterest}
                description={this.props.urlUser.Description}
                tags={this.props.urlUser.Tags ? this.props.urlUser.Tags.join(","): ""}
                github={this.props.urlUser.Github}
                linkedin={this.props.urlUser.Linkedin}
                privacyStatus={this.state.privacyStatus}
                email={this.props.urlUser.EmailAddress}
                
                handleEditClick={this.props.handleEditClick}
                handleFollowClick={this.props.handleFollowClick}
                removeFromSelf={this.props.removeFromSelf}
                removeFromOtherMember={this.props.removeFromOtherMember}
                addToSelf={this.props.addToSelf}
                addToOtherMember={this.props.addToOtherMember}
            />
            :
            <EditProfileCard
                onOwnProfile={this.props.onOwnProfile}
                handlePicSelect={this.handlePicSelect}
                profilePic={this.state.profilePic}
                username={this.props.urlUser.Username}
                name={this.state.name}
                handleNameChange={name => this.setState({name})}
                careerOfInterest={this.state.careerOfInterest}
                handleCareerChange={careerOfInterest => this.setState({careerOfInterest})}
                description={this.state.description}
                handleDescriptionChange={description => this.setState({description})}
                tags={this.state.tags}
                handleTagsChange={tags => this.setState({tags})}
                github={this.state.github}
                handleGithubChange={github => this.setState({github})}
                linkedin={this.state.linkedin}
                handleLinkedinChange={linkedin => this.setState({linkedin})}
                privacyStatus={this.state.privacyStatus}
                handlePrivacyChange={newPrivacyStatus => this.handlePrivacyChange(newPrivacyStatus)}
                email={this.state.email}
                handleEmailChange={email => this.setState({email})}
                isEditing={this.props.isEditing}
                saving={this.state.saving}
                handleSaveChanges={this.handleSaveChanges}
                handleCancelClick={this.props.handleCancelClick}
            />
        );
    }

    handlePrivacyChange = (newPrivacyStatus) => {
        if(newPrivacyStatus) {
            if(this.state.privacyStatus === "PUBLIC") {
                this.setState({privacyStatus: "PRIVATE"});
            } else {
                this.setState({privacyStatus: "PUBLIC"});
            }
        }
    }

    handlePicSelect = e => {
        this.setState({
            newProfilePicture: e.target.files[0]
        });
    }

    handlePicUpload = () => {
        let storageRef = firebase.storage().ref().child(`${this.props.currentUserID}/ProfilePic`);
        return storageRef.put(this.state.newProfilePicture);
    }

    handleSaveChanges = () => {
        this.setState({
            saving: true
        });
        if(this.state.newProfilePicture) {
            this.handlePicUpload().then(() => {
                this.saveToFirebase();
            });
        } else {
            this.saveToFirebase();
        }
    }

    saveToFirebase = () => {
        let name = this.nameSplit();
        let currentUserRef = firebase.database().ref(`Users/${this.props.currentUserID}`)
        currentUserRef.update({
            FirstName: name[0] || "",
            LastName: name[1] || "",
            CareerOfInterest: this.state.careerOfInterest || "",
            Description: this.state.description || "",
            Tags: this.state.tags ? this.state.tags.split(",") : [],
            Github: this.state.github || "",
            Linkedin: this.state.linkedin || "",
            EmailAddress: this.state.email || "",
            Privacy: this.state.privacyStatus
        }, err => {
            if(err) {
                console.log(err);
            } else {
                let storageRef = firebase.storage().ref().child(`${this.props.urlUserID}/ProfilePic`);
                storageRef.getDownloadURL().then(url => {
                    this.setState({
                        profilePic: url,
                        saving: false
                    });
                    currentUserRef.update({
                        ProfilePic: url
                    });
                    this.props.handleCancelClick();
                });
            }
        });
    }

    nameSplit = () => {
        let nameParts = this.state.name.split(" ");
        let result = [];
        result.push(nameParts[0]);
        let lastName = "";
        if(nameParts.length > 1) {
            lastName += nameParts[1];
        }
        for(let i = 2; i < nameParts.length; i++) {
            lastName += " " + nameParts[i];
        }
        result.push(lastName);
        return result;
    }

}